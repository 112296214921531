import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import { Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Icon from "../assests/images/clipart3436228 1.svg"

const buttonWidth = {
    width: "100%",
};

const BookingSuccess = (props) => {
    const { show, handleClose } = props;
    const navigate = useNavigate();

    const handleSubmit = () => {
        handleClose();
        navigate("/services")
    }
    return (
        <Modal show={show} onHide={handleClose} centered size="md">
            
            {/* {showLoader && <LoadingAction />} */}
            <Modal.Body className="px-5 py-4 text-center">
            <Modal.Header closeButton={false} className="border-0 my-0 pt-0 pb-2">
                <div className="text-center mx-auto d-block">
                    <Image src={Icon}/>
                    <Modal.Title className="h5 mt-3">Thank you.</Modal.Title>
                </div>
            </Modal.Header>

                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <h5 className="fw-bolder">Your booking has been successfully created.</h5>
                       
                    </div>


                    <div className="text-center ">
                        <button
                            type="submit"
                            className="btn btn-service py-2 fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default BookingSuccess