import { socket } from "./socket";

export const addUser=(userId)=>{
    socket.emit('add-user',userId,(data)=>{
        console.log('data',data);
      })
}

export const joinRoom =(chatId)=>{
        socket.emit('join-room',chatId,(data)=>{
            console.log(data)
        })
}

export const sendMessage = (message)=>{
    console.log('message-->',message)
    socket.emit('send-message',message,(data)=>{
        console.log("data-->",data)
    })
}

// export const receiveMessage =async()=>{
//     let msg;
//      socket.on('recieve-message',(message)=>{
//       msg=message
//     })
//     console.log("in receive m,esage=========================+>",msg);
//     return msg
// }

socket.on('recieve-message',(message)=>{
      console.log("in receive m,esage 22222222222222222222222=========================+>",message);
    })