import AddCard from "components/addCard";
import ChangePassword from "components/changePassword";
import EditProfile from "components/editProfile";
import MyAccountSidebar from "components/myAccountSidebar";
import Mybookings from "components/myBookings";
import MyCards from "components/myCards";
import ChoosePreferred from "components/ChoosePreferred";

const MyAccount = () => {
  return (
    <>
      <div class="bg-service">
        <div class="container">
          <div class="col-md-12">
            <h1 class="text text-white fw-700">My Account</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <a href="#" class="text-decoration-none text-white fw-700">
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active text-white fw-700"
                  aria-current="page"
                >
                  My Account
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {/* <!-- Details --> */}

      <div class="container mt-5  Account_my">
        <div class="row">
          <MyAccountSidebar />

          <div class="col-lg-8 text-black">
            <div class="card border-0">
              <div class="card-body p-0">
                <div class="tab-content" id="v-pills-tabContent">
                  <EditProfile />
                  <ChangePassword />
                  <Mybookings />
                  <MyCards />
                  <ChoosePreferred />
                  {/* <AddCard /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
