import searchSvg from "assests/images/search.svg";
import defaultUser from "assests/images/download.jpeg";
import { useContext, useEffect, useState } from "react";
import { getChatOfUser, getMessagesOfChat } from "services/services";
import AppContext from "context/appContext";
import { joinRoom } from "utils/socketEvents";
import ReactTimeAgo from "react-time-ago";

const ChatList = ({ fetchMessages, handleUserName, newChat }) => {
  const context = useContext(AppContext);
  const [chats, setChats] = useState([]);

  const getMessages = (chat) => {
    let name = chat.participants.filter(
      (item) => item._id !== context.userId
    )[0].name;
    let avatar = chat.participants.filter(
      (item) => item._id !== context.userId
    )[0].avatar;
    joinRoom(chat._id);
    handleUserName(name, avatar);
    getMessagesOfChat(chat._id, 1).then((res) => {
      if (res.status == 200) {
        fetchMessages(res.data.list);
      }
    });
  };

  const handleSearch = (search) => {
    if (search !== "") {
      let temp = [...chats];
      console.log("temppppp", temp);
      let filtered = temp.filter((item) =>
        item.participants
          .filter((item) => item._id !== context.userId)[0]
          .name.toLowerCase()
          .includes(search.toLowerCase())
      );
      setChats(filtered);
    } else {
      getChats();
    }
  };
  const getChats = () => {
    getChatOfUser().then((res) => {
      if (res.status === 200) {
        if (newChat != "") {
          let temp = [...res.data.list];
          console.log("temp", temp);
          console.log(
            "check",
            temp.some((obj) => obj._id == newChat._id)
          );
          if (!temp.some((obj) => obj._id == newChat._id)) {
            temp.unshift(newChat);
          }
          console.log("tempp", temp);
          setChats(temp);
          getMessages(newChat);
        } else {
          setChats(res.data.list);
        }
      }
    });
  };
  useEffect(() => {
    getChats();
  }, [newChat]);

  // useEffect(()=>{
  //    console.log('newww',newChat)
  //    if(newChat!=''){
  //       let temp =[...chats]
  //       console.log('temp',temp)
  //       console.log('check',temp.some((obj)=>obj._id==newChat._id))
  //     if(!temp.some((obj)=>obj._id==newChat._id)){
  //       temp.unshift(newChat)
  //     }
  //       console.log('tempp',temp);
  //       setChats(temp)
  //       getMessages(newChat)
  //    }
  // },[newChat])
  return (
    <>
      <div class="col-lg-4 col-md-5">
        <div class="bg-chat-color py-5">
          <div class="px-3">
            <h4 class="fw-900">Chats</h4>
            <div class="input-group mb-3">
              <span>
                <img src={searchSvg} alt="search" />
              </span>
              <input
                type="text"
                class="form-control border-0"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <div
            class="nav flex-column nav-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            {chats?.length !== 0 &&
              chats.map((chat) => (
                <div
                  class="nav-link active mb-3"
                  onClick={() => getMessages(chat)}
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  <div class="row">
                    <div class="col-2">
                      <img
                        src={
                          chat?.participants?.filter(
                            (item) => item?._id !== context?.userId
                          )[0]?.avatar
                            ? context?.img_uri +
                              chat.participants.filter(
                                (item) => item?._id !== context?.userId
                              )[0]?.avatar
                            : defaultUser
                        }
                        alt="images"
                        class="rounded-circle img-fix"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div class="col-8">
                      <h6 class="mt-xl-2 mb-1 f-600">
                        {
                          chat?.participants?.filter(
                            (item) => item?._id !== context?.userId
                          )[0]?.name
                        }
                      </h6>
                      <p class="mb-0 text">
                        {chat?.latestMessage?.content[0]?.content}.
                      </p>
                    </div>
                    <div class="col-2">
                      <p class="small mb-0 time text-end">
                        {chat?.latestMessage && (
                          <ReactTimeAgo
                            date={chat?.latestMessage?.createdAt}
                            locale="en-US"
                          />
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            {/* <div class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <div class="row">
                           <div class="col-2">
                              <img src={bgChat} alt="images"  class="rounded-circle" width="50" height="50"/>
                           </div>
                           <div class="col-8">
                              <h6 class="mt-xl-2 mb-1 f-600">Shane Christanio</h6>
                              <p class="mb-0 text">Let’s go to the Music concert next week.</p>
                           </div>
                           <div class="col-2">
                              <p class="small mb-0 time text-end">3 days ago</p>
                           </div>
                        </div>
                     </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatList;
