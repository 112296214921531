import googleIcon from 'assests/images/google.svg'
import facebookIcon from 'assests/images/face.svg'
import appleIcon from 'assests/images/apple.svg'
import React,{useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { userSignin } from 'services/services';
import { useContext } from 'react';
import AppContext from 'context/appContext';
import { toast } from 'react-toastify';
import { BiSolidHide,BiShow } from "react-icons/bi";


import axios from 'axios';
const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const context = useContext(AppContext)
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const onSubmit = data => {
        data['type'] = "user"
        userSignin(data).then(res => {
            if (res.status == 200) {
                if ((res.data.data.serviceType == "business" && res?.data?.data?.is_approved) || res.data.data.serviceType == "individual") {
                    context.setToken(res.data.data.token)
                    context.setUserId(res.data.data._id)
                    context.setHavePaymentMethod(res.data.data.have_default_payment_method)
                    context.setUserType(res.data.data.serviceType)
                    localStorage.setItem('token', res.data.data.token)
                    localStorage.setItem('userId', res.data.data._id)
                    localStorage.setItem('have_payment_method', res.data.data.have_default_payment_method)
                    localStorage.setItem('userType', res.data.data.serviceType)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.token}`;
                   // localStorage.setItem('userType', res.data.data.)
                    toast.success("logged in successfully.")
                    navigate('/home')
                }
                else if (res.data.data.serviceType == "business" && !res?.data?.data?.is_approved) {
                    navigate('/login')
                    toast.success("Please wait sometime because your account admin is not approved")
                }
            }
        }).catch(err => {
            console.log('err', err);
            if (err.response?.status == 400) {
                toast.error(err.response.data.message)
            }
        })
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };


    return (
        <>
            <div class="bg-sign">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">Sign in</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><Link to='/login' class="text-decoration-none text-white fw-700">Home</Link></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">Sign in</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container mt-5 text-black">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-8">
                        <h2 class="text-center fw-900">Hi, Welcome Back!</h2>
                        <p class="text-center fw-600">Still do not have an account? <Link to='/signup' class="color text-decoration-none fw-900">Sign up</Link></p>
                        <form action="" class="contact-form mt-5" onSubmit={handleSubmit(onSubmit)}>
                            <div class="mb-4">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" class="form-control" name="email" {...register('email', { required: "Email is required", pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Please enter valid email address." } })} />
                                {errors.email && <span className='err-msg'>{errors.email.message}</span>}
                            </div>
                            <div class="mb-4 position-relative">
                                <label for="password" class="form-label">Password</label>
                                <input type={showPassword ? "text" : "password"} class="form-control" name="password" {...register('password', { required: "Password is required." })} />
                                <div className='password-hide-show' onClick={togglePasswordVisibility}>{showPassword ? <BiShow /> : <BiSolidHide />} </div>
                                {errors.password && <span className='err-msg'>{errors.password.message}</span>}
                            </div>
                            <div class="mb-4">
                                <div class="clearfix">
                                    <div class="float-start">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                            <label class="form-check-label" for="exampleCheck1">Keep me logged in</label>
                                        </div>
                                    </div>
                                    <div class="float-end">
                                        <Link to='/forgot-password' class="fw-700 color text-decoration-none">Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="mx-xl-5 px-md-5 my-5">
                                <button class="btn btn-book w-100 py-3">Sign in</button>
                            </div>
                            {/* <p class="text-center text-muted mb-4">or connect with social account</p> */}
                            {/* <div class="text-center"> */}
                                {/* <a href="" class="text-decoration-none">
                                    <img src={googleIcon} alt="google"/>
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src={facebookIcon} alt="facebook"/>
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src={appleIcon} alt="apple"/>
                                </a> */}
                                {/* <div id="buttonDiv" ref={context?.googleRef}></div>
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;