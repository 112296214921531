import { Link,useNavigate } from "react-router-dom";
import React,{useState} from "react";
import { userForgetPass } from 'services/services';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [emailErr,setEmailErr] = useState('')

//forgot-verification

const handlerChange = (e)=>{
    let value = e.target.value
    if(value.length>3){
        setEmailErr('')
    }
    setEmail(value)
}

const submitHandler = (e)=>{
   // navigate('/forgot-verification')
   e.preventDefault()
   if(!email){
    return  setEmailErr("Email is required")
   }

    let userData = {
        email:email
    }
    userForgetPass(userData).then(res => {
        if (res.status == 200) {
            localStorage.setItem("emailForgetPass", email);
            toast.success(res?.data?.message);
            navigate('/forgot-verification')
          setEmailErr('')
          setEmail('')
        }
    }).catch(err => {
        console.log('err', err);
        if (err.response?.status == 400) {
            toast.error(err.response.data.message)
        }
    })

}
//
    return (
        <>
            <div class="bg-forgot">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">Forgot Password</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><Link to='/login' class="text-decoration-none text-white fw-700">Home</Link></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">Forgot Password</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container mt-5 text-black">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-8">

                        <h2 class="text-center fw-900">Forgot Password?</h2>
                        <p class="text-center fw-600">Don’t worry! it happens. Please enter the email id
                            associated with your account.</p>

                        <form action="" class="contact-form mt-5">
                            <div class="mb-4">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" class="form-control" name="email" 
                                value={email}
                                onChange={handlerChange}
                                />
                                {emailErr.length>0 && <span className='err-msg'>{emailErr}</span>}
                            </div>


                            <div class="mx-xl-5 px-md-5 my-5">
                                <button class="btn btn-book w-100 py-3" onClick={submitHandler}>Submit</button>
                            </div>

                        </form>


                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;