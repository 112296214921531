import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import FavIcon from "../assests/images/logo.svg"
import { getChoosePreferred } from "services/services";

const ChoosePreferred = () => {

  const [choosePreferred, setChoosePreferred] = useState('')
  const [acceptNotaryList, setAcceptNotaryList] = useState([])
  const handleChangePreferred = (e) => {
    setChoosePreferred(e.target.value)
  }

  const getPreferredNotary = async () => {
    getChoosePreferred().then((res) => {
      if (res.status == 200) {
        setAcceptNotaryList(res.data.data)
      }
    });
  }

  useEffect(() => {
    getPreferredNotary()
  }, [])
  return (
    <>
      <div class="tab-pane fade" id="v-7">
        <div class="card-header border-0 theme-color py-3">
          <span class="text-white fw-bolder ms-2">Choose Preferred</span>
        </div>
        <ul class="list-group p-4 My_Cards">

          <div className="mb-4">
            <label htmlFor="userType" className="form-label">
              Choose Preferred
            </label>
            <select
              className="form-select"
              name="userType"
              value={choosePreferred}
              onChange={handleChangePreferred}
            >
               <option value="">Select Preferred Notary</option>
              {acceptNotaryList.map((option) => (             
                  <option key={option.assigned_to._id} value={option.assigned_to.name}>
                    {option.assigned_to.name}
                  </option>           
              ))}
            </select>

          </div>
          <div className="mb-4">
            <p className="text-muted">
              Preferred Notary
            </p>
            <div className="clearfix mt-4">
              <div className="float-start">
                <img src={FavIcon} alt="" />
              </div>
              <div className="float-end">
                <h3 className="mb-0  mt-4 color fw-bolder">{choosePreferred.length > 0 ? choosePreferred : "No Notary"}</h3>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </>
  );
};



export default ChoosePreferred