import { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";

const TimeSlots = ({ startTime, endTime, slotDifference, onSelect}) => {
    // const {register,watch}= useForm()
    const [timeSlots, setTimeSlots] = useState([])

    //startTime and endTime is props
    function generateTimeSlots() {
        const timeSlots = [];
        let currentTime = startTime;
        while (currentTime <= endTime) {
            // Format the time
            const formattedTime = formatTime(currentTime);

            // Add the time slot to the array
            timeSlots.push({
                value: formattedTime,
                label: formattedTime,
            });

            // Increment time by 30 minutes
            currentTime += slotDifference;
        }
        return timeSlots;
    }

    // Helper function to format time
    function formatTime(time) {
        const hours = Math.floor(time);
        const minutes = (time % 1) * 60;
        const meridiem = hours >= 12 ? 'PM' : 'AM';
        // Convert to 12-hour format
        const hours12 = hours % 12 || 12;
        
        // Format the time as HH:mm
        const formattedTime = `${String(hours12).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${meridiem}`;

        return formattedTime;
    }
    
    useEffect(() => {
        setTimeSlots(generateTimeSlots())
    }, []);


    return (
        <>
            <div class="row">
                {timeSlots.length !== 0 && timeSlots.map((slot) =>
                    <div class="col-xl-2 col-lg-2 col-4">
                        <div class="mb-3">
                            <input type="radio" value={slot.value} class="btn-check" name="options" id={slot.value} autocomplete="off" onChange={(e)=>onSelect(e.target.value)} />
                            <label class="btn btn-secondary w-100" for={slot.value}>{slot.label}</label>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default TimeSlots;