import { Route, Routes,Navigate } from "react-router-dom";
import Login from "pages/login";
import Signup from "pages/signup";
import ForgotPassword from "pages/forgetPassword";
import Home from "pages/home";
import { useContext, useEffect } from "react";
import AppContext from "context/appContext";
import Privacy from "pages/privacy-policies";
import AboutUs from "pages/aboutus";
import ContactForm from "pages/contact-us";
import Terms from "pages/terms";
import Services from "pages/services";
import Chat from "pages/chat";
import ServiceDetails from "pages/service-details";
import ServiceForm from "pages/service-form";
import Faq from "pages/faqs";
import MyAccount from "pages/myAccount";
import JobDetails from "pages/jobDetails";
import Pricing from "pages/pricing";
import UserCard from "pages/card";
import ForgotVerification from "pages/verifyOtp";
import ResetPassword from "pages/ResetPassword";

const AppRouting = () => {
  const context = useContext(AppContext);
  const isAuth = context.token !== null && context.token !== "";

  return (
    <>
      {/* <ScrollRestoration /><Navigate to={'/login'} /> */}
      <Routes>
      <Route path="/" element={<Navigate to={'/home'} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route
          path="/service/:slug"
          element={isAuth ? <ServiceDetails /> : <Login />}
        />
        <Route
          path="/service-form"
          element={isAuth ? <ServiceForm /> : <Login />}
        />
        <Route path="/account" element={isAuth ? <MyAccount /> : <Login />} />
        <Route path="/chat" element={isAuth ? <Chat /> : <Login />} />
        <Route
          path="/job-details/:id"
          element={isAuth ? <JobDetails /> : <Login />}
        />
        <Route path="/add-card" element={isAuth ? <UserCard /> : <Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-verification" element={<ForgotVerification />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact-us" element={<ContactForm />} />
        {/* <Route path="/home" element={<PrivateRoutes isAuthenticated={isAuth}><Home/></PrivateRoutes>}/> */}
      </Routes>
    </>
  );
};

export default AppRouting;
