import icon1 from 'assests/images/icon-1.svg'
import icon2 from 'assests/images/icon-2.svg'
import icon3 from 'assests/images/icon-3.svg'
import icon4 from 'assests/images/icon-4.svg'
import AppContext from 'context/appContext'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCities, getLanguages, getServices, getStates } from 'services/services'

const Services = () => {
    const navigate = useNavigate()
    const context = useContext(AppContext)
    const [services, setServices] = useState([])

    const handleBookings = (type,service)=>{
            if(type=="immediate"){
                const date=moment().add(1,'hour').toISOString()
                service.selectedDate=date
                service.jobType = "immediate"
                navigate('/service-form',{state:service})
            }
            if(type=="schedule"){
                service.jobType = "schedule"
                navigate(`/service/${service.service_name}`, { state: service })
            }
    }
    useEffect(() => {
        const GetServices = () => {
            getServices().then(response => {
                if (response.status == 200) {
                    setServices(response.data.list)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
    
        GetServices()
    }, [])
    return (
        <>
            <div class="bg-service">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">Service</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#" class="text-decoration-none text-white fw-700">Home</a></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">Services</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <!-- Details --> */}

            <div class="container mt-5 text-black">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center fw-900">Our Services</h2>
                    </div>
                </div>
            </div>

            <div class="container mt-4">
                <div class="row">
                    {services?.length !== 0 && services.map((service) =>
                        <div key={service.service_name} class="col-lg-6">
                            <div class="card mb-4 border-0">
                                <div class="card-body p-4">
                                    <div class="row g-0">
                                        <div class="col-md-2">
                                            <img src={context.img_uri + service.img} class="img-fluid rounded-start" alt="icons" />
                                        </div>
                                        <div class="col-md-10">
                                            <h5 class="card-title fw-900 m-img">{service.service_name}</h5>
                                            <p class="card-text">{service.service_description}</p>
                                            {/* <p style={{fontWeight:"bolder"}} class="card-text">$ {service.price}</p> */}
                                            {/* <button class="btn btn-book" onClick={() => navigate(`/service/${service.service_name}`, { state: service })}>Book Now</button> */}

                                            <div class="dropdown" >
                                                <button class="btn btn-books dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Book Now
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" onClick={(e)=>handleBookings('immediate',service)} >Immediate</a>
                                                    <a class="dropdown-item" onClick={(e)=>handleBookings('schedule',service)}>Schedule</a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* <div class="col-lg-6">
                        <div class="card mb-4 border-0">
                            <div class="card-body p-4">
                                <div class="row g-0">
                                    <div class="col-md-2">
                                        <img src={icon2} class="img-fluid rounded-start" alt="icons"/>
                                    </div>
                                    <div class="col-md-10">
                                        <h5 class="card-title fw-900 m-img">Real Estate Transaction</h5>
                                        <p class="card-text">Consectetur adipiscing elit, sed do eiusm onsectetur
                                            adipiscing elit, sed do eiusm od tempor.</p>
                                        <button class="btn btn-book">Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card mb-4 border-0">
                            <div class="card-body p-4">
                                <div class="row g-0">
                                    <div class="col-md-2">
                                        <img src={icon3} class="img-fluid rounded-start" alt="icons"/>
                                    </div>
                                    <div class="col-md-10">
                                        <h5 class="card-title fw-900 m-img">I-9 Verification</h5>
                                        <p class="card-text">Consectetur adipiscing elit, sed do eiusm onsectetur
                                            adipiscing elit, sed do eiusm od tempor.</p>
                                        <button class="btn btn-book ">Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card mb-4 border-0">
                            <div class="card-body p-4">
                                <div class="row g-0">
                                    <div class="col-md-2">
                                        <img src={icon4} class="img-fluid rounded-start" alt="icons"/>
                                    </div>
                                    <div class="col-md-10">
                                        <h5 class="card-title fw-900 m-img">Oath Administration/ Sworn Statement</h5>
                                        <p class="card-text">Consectetur adipiscing elit, sed do eiusm onsectetur
                                            adipiscing elit, sed do eiusm od tempor.</p>
                                        <button class="btn btn-book ">Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default Services;