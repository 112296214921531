import React from "react";
import AppContext from "context/appContext";
import { toast } from "react-toastify";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { addPaymentMethod } from "services/services";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const AddCard = () => {
  const context = useContext(AppContext);
  let navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const addCard = async (event) => {
    // console.log(event);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const token = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );

    addPaymentMethod({ ...token, isPrimary: true })
      .then((res) => {
        if (res.status == 200) {
          context.setHavePaymentMethod(true);
          localStorage.setItem("have_payment_method", true);
          toast.success(res.data.message);
          navigate("/services");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <>
      <div class="tab-pane fade" id="v-7">
        <div class="card-header border-0 theme-color py-3">
          <span class="text-white fw-bolder ms-2">Add Card</span>
        </div>
        <form action="" class="contact-form p-4">
          <div class="container text-black ">
            <div class="row justify-content-center">
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-md-12">
                    <div class="mb-4">
                      <label for="name" class="form-label">
                        Name on Card
                      </label>
                      <input type="text" class="form-control" id="name" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-4">
                      <label for="number" class="form-label">
                        Card Number
                      </label>
                      <input type="text" class="form-control" id="number" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-md-0 mb-4">
                      <label for="exp-date" class="form-label">
                        Expiry Month
                      </label>
                      <input type="text" class="form-control" id="exp-date" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-md-0 mb-4">
                      <label for="exp-date" class="form-label">
                        Expiry Year
                      </label>
                      <input type="text" class="form-control" id="exp-date" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-4">
                      <label for="cvv" class="form-label">
                        CVV
                      </label>
                      <input type="text" class="form-control" id="cvv" />
                    </div>
                  </div>
                </div>
                <button class="btn btn-continue py-3 px-md-5">Add Card</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCard;
