import { useEffect, useState } from "react";
import { getStaticContent } from "services/services";

const Pricing = () => {
    const [content, setContent]= useState('')
    useEffect(() => {
        const getContent=()=>{
            getStaticContent(4).then(response=>{
                if(response.status==200){
                    setContent(response.data.data.content)
                }
            })
        }
        getContent()
    }, []);
    return (
        <>
            <div class="bg-privacy">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">Pricing</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#" class="text-decoration-none text-white fw-700">Home</a></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">Pricing</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <!-- Pricing --> */}

            <div class="container mt-5 text-black">
                {/* <div class="row">
                    <div class="col-md-12">
                        <h4 class="fw-900 mb-4"> At Mobile Notary X, we understand that each notarization requirement is unique, and
                            pricing may vary based on factors such as location and the type of service selected. We
                            strive to offer competitive and fair pricing while delivering exceptional service.
                        </h4>

                        <h4 class="fw-900 color">Factors Influencing Pricing</h4>
                        <ul class="ps-3 mb-4">
                            <li>
                                <h5 class="fw-900">Location</h5>
                                <p class="fw-600">The location where the notary service is needed can influence pricing due to
                                    travel time and distance. Our pricing structure may differ based on your specific area or
                                    region.
                                </p>
                            </li>
                            <li>
                                <h5 class="fw-900">Service Selected</h5>
                                <p class="fw-600">The type and complexity of the service requested also impact pricing.
                                    While staying within the mandated state pricing guidelines for notarizations, certain
                                    documents or services such as Real Estate transactions and/or administrative tasks
                                    may require more time, specialized knowledge, or additional steps, which can affect the
                                    overall cost.
                                </p>
                            </li>
                        </ul>

                        <h4 class="fw-900 color">Request a Quote</h4>
                        <p class="fw-600 mb-4">
                            For accurate pricing information tailored to your needs, please contact us to discuss
                            your specific document notarization requirements. Our friendly team will provide you
                            with a customized quote based on your location and the service you require.
                        </p>
                        <h4 class="fw-900 color">Transparent and Competitive Pricing</h4>
                        <p class="fw-600 mb-4">
                            We are committed to transparency in our pricing structure, ensuring that you receive fair
                            and competitive rates for our professional notary services. Rest assured, we prioritize
                            affordability without compromising on the quality and accuracy of our services.
                        </p>
                        <h4 class="fw-900 color">Schedule Your Appointment</h4>
                        <p class="fw-600 mb-4">
                            To receive a personalized quote or to schedule an appointment, please get in touch with
                            us. We're here to assist you in notarizing your documents conveniently and efficiently.
                        </p>

                        <h4 class="fw-900 color">Payment Pre-Authorization and Completion Disclaimer</h4>
                        <p class="fw-600">
                            At Mobile Notary X, we operate on a pre-authorization payment system to secure your
                            appointment. Please note the following terms regarding payment and completion of
                            services:
                        </p>

                        <ul class="ps-3">
                            <li>
                                <h5 class="fw-900">Pre-Authorization of Payment</h5>
                                <p class="fw-600">Upon booking an appointment for notary services, we
                                    pre-authorize payment as a security measure. This pre-authorization confirms your
                                    commitment to the scheduled service.
                                </p>
                            </li>
                            <li>
                                <h5 class="fw-900">Payment Upon Completion</h5>
                                <p class="fw-600">The actual charge for the notary service will occur once the
                                    job is completed, subject to successful notarization.
                                </p>
                            </li>
                            <li>
                                <h5 class="fw-900">Reasons for Travel Fee Even if Notarization Is Not Completed</h5>
                                <p class="fw-600"> In certain circumstances
                                    where notarization cannot be completed due to factors beyond our control or negligence
                                    on the customer's part, the travel fee may still be charged to compensate for the
                                    notary's time and expenses. These instances include but are not limited to:
                                </p>
                            </li>
                        </ul>

                        <ol class="mb-4">
                            <li>Improper Identification presented by the signer.</li>
                            <li>Inability of the signer to demonstrate an understanding of the documents being
                                signed.
                            </li>
                            <li>Documents presented in a language other than what was selected upon booking
                                the appointment.
                            </li>
                            <li>Absence of the signer during the scheduled appointment time</li>
                            <li>Incorrect address provided by the customer.</li>
                            <li>Cancellation of the appointment within 2 hours of the scheduled time.</li>
                            <li>Any other reasons that are attributable to the neglect or oversight of the
                                customer.
                            </li>
                        </ol>

                        <ul class="ps-3 mb-4">
                            <li>
                                <h5 class="fw-900">Customer Responsibility</h5>
                                <p class="fw-600">Customers are responsible for providing accurate information,
                                    valid identification, and ensuring their availability at the scheduled appointment time and
                                    location to avoid unnecessary charges.
                                </p>
                            </li>
                        </ul>

                        <p class="fw-600">By booking an appointment with Mobile Notary X, you acknowledge and agree to these
                            payment terms and conditions.
                        </p>
                        <p class="fw-600">Please feel free to contact us if you have any questions or require further clarification
                            regarding our payment policies.
                        </p>
                    </div>
                </div> */}

                <div dangerouslySetInnerHTML={{__html:content}}/>
            </div>
        </>
    );
}

export default Pricing;