import React, { useState } from "react";
import AppContext from "context/appContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addPaymentMethod, createJob } from "services/services";
import BookingSuccess from "./BookingSuccess";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
const UserCard = () => {
  const stripe = useStripe();
  const elements = useElements();
  const context = useContext(AppContext);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isChecked, setIsChecked] = useState(false);
  const [errorChecked, setErrorChecked] = useState('');
  const [show,setShow] = useState(false)


  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const addCard = async (event) => {

    console.log("context--->",context)
    event.preventDefault();
    const length = context?.jobCreateSuccess ? Object.keys(context?.jobCreateSuccess).length :0;
    //context.setJobCreateSuccess('')

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!isChecked) {
      setErrorChecked("Please accept this.")
    }

    try {
      const token = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );
      console.log("2222", token);
      if (token.error) {
        // Handle token creation error
        if (token.error.code === "incorrect_number") {
          toast.error("Please enter valid card details.");
        } else {
          toast.error(token.error.message);
        }
        return;
      }
  let bookingCheck=false
      if (length > 10) {
        if (token) {
          createJob(context?.jobCreateSuccess)
            .then((response) => {
              if (response.status == 201) {
                bookingCheck=true
                localStorage.removeItem('jobCreateSuccess');
                context.setJobCreateSuccess('')
              }
            })
            .catch((err) => {
              bookingCheck=true
              console.log(err);
              toast.error(err.response.data.message);
            });
        }

      }


      // Assuming addPaymentMethod returns a promise
      addPaymentMethod({ ...token, isPrimary: true })
        .then((res) => {
          if (res.status === 201) {
            context.setHavePaymentMethod(true);
            localStorage.setItem("have_payment_method", true);
            console.log(bookingCheck,"bookingCheck")
            if(bookingCheck){
              setShow(true)
              bookingCheck=false
            }else{
              toast.success(res.data.message);
              navigate("/services");
            }
           
          }
        })
        .catch((err) => {
          bookingCheck=false
          toast.error(err.response.data.message);
        });
    } catch (error) {
      // Handle Stripe token creation error
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleClose = ()=>{ setShow(false)}

  return (
    <>
      <div class="bg-payment">
        <div class="container">
          <div class="col-md-12">
            <h1 class="text text-white fw-700">Payment</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <a href="#" class="text-decoration-none text-white fw-700">
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active text-white fw-700"
                  aria-current="page"
                >
                  Payment
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {/* <!-- Form --> */}

      <div class="container text-black mt-5">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <h4 class="fw-900">Add Card Information</h4>
              {/* <div>Please Enter your Card Details</div> */}
            </div>
          </div>
        </div>
      </div>
        <BookingSuccess show={show} handleClose={handleClose} />
      <form class="service-form" onSubmit={addCard}>
        {/* <div class="container mt-5 text-black ">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8 col-md-10">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-4">
                                        <label for="name" class="form-label">Name on Card</label>
                                        <input type="text" class="form-control" id="name" {...register('name',{required:"Please enter your name."})}/>
                                    </div>
                                    {errors.name && <span className='err-msg'>{errors.name.message}</span>}
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-4">
                                        <label for="number" class="form-label">Card Number</label>
                                        <input type="text" class="form-control" id="number" {...register('number',{required:"Card Number is required."})}/>
                                    </div>
                                    {errors.number && <span className='err-msg'>{errors.number.message}</span>}
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-md-0 mb-4">
                                        <label for="exp-date" class="form-label">Expiry Month</label>
                                        <input type="text" class="form-control" id="exp-date" {...register('month',{required:"Please enter card expiry month."})}/>
                                    </div>
                                    {errors.month && <span className='err-msg'>{errors.month.message}</span>}
                                </div>
                                <div class="col-md-4">
                                    <div class="mb-md-0 mb-4">
                                        <label for="exp-date" class="form-label">Expiry Year</label>
                                        <input type="text" class="form-control" id="exp-date" {...register('year',{required:"Please enter card expiry year."})}/>
                                    </div>
                                    {errors.year && <span className='err-msg'>{errors.year.message}</span>}
                                </div>
                                <div class="col-md-4">
                                    <div class="">
                                        <label for="cvv" class="form-label">CVV</label>
                                        <input type="password" class="form-control" maxLength={5} id="cvv" {...register('cvv',{required:"CVV is required"})}/>
                                    </div>
                                    {errors.cvv && <span className='err-msg'>{errors.cvv.message}</span>}
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-4 mt-3 form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" {...register('saveCheck',{required:"This checkbox is required"})}/>
                                            <label class="form-check-label" for="exampleCheck1">Save Card</label>
                                    </div>
                                    {errors.saveCheck && <span className='err-msg'>{errors.saveCheck.message}</span>}
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-continue w-100 mt-3">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div class="container mt-1 text-black ">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8 col-md-10">
              <div className="">
                <ul>
                  <li>
                    To proceed with booking your notarization, a payment card is
                    required.
                  </li>
                  <li>
                    Charges will be applied AFTER your notary appointment is
                    complete.
                  </li>
                  <li>
                    Pricing details will be available for you to review before
                    you finalize the booking request.
                  </li>
                </ul>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-4">
                    <label for="number" class="form-label">
                      Card Number
                    </label>
                    <CardNumberElement className="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-4">
                    <label for="number" class="form-label">
                      Card Expiry
                    </label>
                    <CardExpiryElement className="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-4">
                    <label for="number" class="form-label">
                      Card CVV
                    </label>
                    <CardCvcElement className="form-control" />
                  </div>
                </div>
                {/* <div class="col-md-12">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      checked={isChecked}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="exampleCheck1">
                      Acknowledging the price
                    </label>
                  </div>
                  {console.log('errorChecked->', errorChecked)}
                  {!isChecked && (
                    <div className=" checked-price">{errorChecked}</div>
                  )}
                </div> */}

                <div class="col-md-12">
                  <button class="btn btn-continue w-100 mt-3">Continue</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserCard;
