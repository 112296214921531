import Icon5 from 'assests/images/icon-5.png'
import Calender from 'assests/images/calendar.svg'
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Autocomplete from "react-google-autocomplete";
import RealEstateTransaction from 'components/RealEstate';
import I9Verification from 'components/I-9verification';
import OathAdminstration from 'components/oathAdminstration';
import StandardNotarization from 'components/standardNotarization';

const ServiceForm = () => {
    const { state } = useLocation()
    console.log(state, moment(state.selectedDate).format('HH:mm dddd,MMMM DD,YYYY'))
    return (
        <>
            {state.service_name == "Standard Notarization" ?
                <StandardNotarization state={state} /> :
                state.service_name == "Real Estate Transaction" ?
                    <RealEstateTransaction state={state} /> :
                    state.service_name == "I-9 Verification" ?
                        <I9Verification state={state} /> :
                        state.service_name == "Oath Administration-Sworn Statement" ?
                            <OathAdminstration state={state} /> :
                            <p>No service found</p>
            }
        </>
    );
}

export default ServiceForm;