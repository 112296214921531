import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { editAddedCard, getAddedCards } from "services/services";
import { useForm } from "react-hook-form";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { DeleteCard } from "utils/restrictionPopUp";

const MyCards = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const [cards, setCards] = useState([]);
  const [editCard, setEditCard] = useState(false);
  const [editId, setEditId] = useState("");
  const [editIsPrimary, setEditIsPrimary] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const editCardDetails = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error, token } = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );

    if (error) {
      toast.error(error.message);
      return;
    }
    editAddedCard({ token, isPrimary: editIsPrimary }, editId)
      .then((response) => {
        if (response.status == 200) {
          toast.success(response?.data?.message);
          setEditCard(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const showEditCard = (id) => {
    setEditId(id);
    setEditCard(true);
  };
  const getCards = () => {
    getAddedCards()
      .then((response) => {
        if (response.status == 200) {
          setCards(response?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getCards();

    return () => {
      setEditCard(false);
    };
  }, []);

  const handleTransactionStatus = (stripeCardId) => {
    DeleteCard(stripeCardId, getCards);
  };

  return (
    <>
      <div class="tab-pane fade" id="v-6">
        {!editCard && (
          <>
            <div class="card-header border-0 theme-color py-3">
              <span class="text-white fw-bolder ms-2">My Cards</span>
            </div>
            <ul class="list-group p-4 My_Cards">
              {cards?.map((item) => (
                <li
                  key={item._id}
                  class="list-group-item d-flex justify-content-between align-items-start py-3 mb-4 border"
                >
                  <div class="me-auto">
                    <div class="fw-bold">
                      <i class="fa fa-credit-card me-2"></i>
                      <span>XXXX XXXX XXXX {item?.last4}</span>
                    </div>
                  </div>
                  <span class="">
                    {/* <button
                      class="text-decoration-none color me-2 After_Line"
                      onClick={()=>showEditCard(item._id)}
                      style={{ border: 'none', outline: 'none' }} 
                 
                    >
                      <i class="fa fa-edit me-1"></i> Edit
                    </button> */}
                    {cards.length>1 && <button
                      class="text-decoration-none text-danger me-2"
                      onClick={() => handleTransactionStatus(item._id)}
                      style={{ border: "none", outline: "none" }}
                    >
                      <i class="fa fa-trash me-1"> </i> Delete
                    </button> }
                    
                  </span>
                </li>
              ))}
              {cards.length === 0 && <p>No card added. Add your card now</p>}
            </ul>
            <div class="px-4 pb-4">
              <Link to={"/add-card"} class="btn btn-book py-3 px-md-5">
                <i class="fa fa-plus"></i> Add New Card
              </Link>
            </div>
          </>
        )}

        {/* {editCard && (
          <>
            <div class="card-header border-0 theme-color py-3">
              <span class="text-white fw-bolder ms-2">Add Card</span>
            </div>
            <form
              action=""
              class="contact-form p-4"
              onSubmit={handleSubmit(editCardDetails)}
            >
              <div class="container text-black ">
                <div class="row justify-content-center">
                  <div class="col-12 px-0">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-4">
                        <label for="number" class="form-label">Card Number</label>
                                        <CardNumberElement className="form-control" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-md-0 mb-4">
                        <label for="number" class="form-label">Card Expiry</label>
                                        <CardExpiryElement className="form-control" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-md-0 mb-4">
                        <label for="number" class="form-label">Card CVV</label>
                                        <CardCvcElement className="form-control" />
                        </div>
                      </div>
                   

                      <div class="col-md-12">
                        <div class="mb-4 form-check mt-4">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="make-in-use"
                            checked={editIsPrimary}
                            onChange={()=>setEditIsPrimary(!editIsPrimary)}
                          />
                          <label class="form-check-label" for="make-in-use">
                            Make In Use
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      class="btn btn-continue py-3 px-md-5 me-3"
                      onClick={() => setEditCard(false)}
                    >
                      Back
                    </button>
                    <button type="submit" class="btn btn-continue py-3 px-md-5">
                      Update Card
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )} */}
      </div>
    </>
  );
};

export default MyCards;
