import searchSvg from "assests/images/search.svg";
import bgChat from "assests/images/bg-chat.png";
import bgService from "assests/images/bg-service.png";
import { getAllUsers, getOrCreateChatRoom } from "services/services";
import { useContext, useEffect, useRef, useState } from "react";
import ChatList from "components/chatList";
import ChatMessages from "components/chatMessages";
import { socket } from "utils/socket";
import { receiveMessage } from "utils/socketEvents";
import { scrollToBottom } from "utils/scrollInChat";
import { useLocation, useSearchParams } from "react-router-dom";
import AppContext from "context/appContext";

const Chat = () => {
  const context = useContext(AppContext);
  const [userName, setUserName] = useState("");
  const [userImg, setUserImg] = useState("");
  const [messages, setMessages] = useState([]);
  const [newChatRoom, setNewChatRoom] = useState("");
  let scrollableRef = useRef(null);

  const handleChatMessages = (messages) => {
    console.log(messages);
    setMessages(messages);
  };
  const handleUserInfo = (name, img) => {
    setUserName(name);
    setUserImg(img);
  };
  socket.on("recieve-message", (message) => {
    console.log('message--->recieved---->',message)
    let temp = [...messages];
    console.log("temp", temp);
    temp.unshift(message);
    setMessages(temp);
    scrollToBottom(scrollableRef);
  });

  useEffect(() => {
    const newChatRoom = () => {
      console.log("assigned", context.assigned);
      let payload = {
        receiver: context.assigned,
      };
      getOrCreateChatRoom(payload).then((res) => {
        if (res.status == 201 || res.status == 200) {
          setNewChatRoom(res.data.data);
        }
      });
    };
    if (context.assigned) {
      console.log("hitt");
      newChatRoom();
    }
  }, []);
  return (
    <>
      <div class="bg-sign">
        <div class="container">
          <div class="col-md-12">
            <h1 class="text text-white fw-700">Chat</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <a href="#" class="text-decoration-none text-white fw-700">
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active text-white fw-700"
                  aria-current="page"
                >
                  Chat
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="container mt-5 text-black">
        <div class="row justify-content-center">
          <ChatList
            fetchMessages={(data) => handleChatMessages(data)}
            handleUserName={(name, img) => handleUserInfo(name, img)}
            newChat={newChatRoom}
          />

          <ChatMessages
            messages={messages}
            userName={userName}
            userAvatar={userImg}
            scrollRef={scrollableRef}
            newChat={newChatRoom._id}
          />
        </div>
      </div>
    </>
  );
};

export default Chat;
