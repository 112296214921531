import Icon5 from "assests/images/icon-5.png";
import Calender from "assests/images/calendar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Autocomplete from "react-google-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { createJob, getFlatprices, getService, getAddedCards } from "services/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import AppContext from "context/appContext";

const OathAdminstration = ({ state }) => {
  const context = useContext(AppContext);
  let {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState("");
  const [flatPriceData, setFlatPriceData] = useState([]);
  const [servicePriceData, setServicePriceData] = useState([]);
  const [cards, setCards] = useState([]);
  const [travelFee, setTravelFee] = useState(0);
  const [flatPrice, setFlatPrice] = useState(0);
  const id = state._id;


  const getCards = () => {
    getAddedCards()
      .then((response) => {
        if (response.status == 200) {
          setCards(response?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getCards();
  }, []);

  const subnitForm = (data) => {
    const location = {
      place_id: data?.location?.place_id,
      lat: data?.location?.geometry.location?.lat(),
      long: data?.location?.geometry.location?.lng(),
      name: data?.location?.formatted_address,
    };
    const form_data = {
      name: data.name,
      email: data.email,
      service: state._id,
      loc: JSON.stringify(location),
      schedule_date: state.selectedDate,
      job_type: state.jobType,
      state: data.state,
      city: data.city,
      language: data.language,
      have_the_proper_original_identification: data.original_identification,
      are_you_giving_sworn_statement: data.giving_sworn_statement,
      relation_with_person_giving_sworn_statement:
        data.relation_with_giving_sworn,
      service_charge_per_hour: state.price,
    };
    console.log("submit", data);
    if (cards.length == 0 && context.userType == "individual") {
      toast.error("No card on your profile please add card");
      context.setJobCreateSuccess(form_data)
      localStorage.setItem('jobCreateSuccess', JSON.stringify(form_data))
      setTimeout(() => {
        navigate("/add-card")
      }, 1000)
    } else {

      createJob(form_data)
        .then((response) => {
          if (response.status == 201) {
            toast.success("job created successfully.");
            navigate("/services");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
        });
    }

  };

  const onChangeState = (value) => {
    let isStateHavePrice = flatPriceData.filter(
      (item) => item.state.name == value
    );

    if (isStateHavePrice.length !== 0) {
      return true;
    } else {
      setSelectedState("");
      return "Service is not available for this state.";
    }
  };

  const onChangeCity = (value) => {
    let cityArr = flatPriceData.filter(
      (item) => item.state.name == selectedState
    )[0].cities;
    console.log(cityArr);
    let isCityHavePrice = cityArr.filter((item) => item?.name == value);

    if (isCityHavePrice?.length == 0) {
      return "Service is not available for this city.";
    } else {
      return true;
    }
  };

  const handleTravelFee = (event) => {
    let city = flatPriceData
      .filter((item) => item.state.name == selectedState)[0]
      ?.cities?.filter((item) => item?.name == event.target.value)[0];
    setTravelFee(city?.price || 0);
  };
  const handleNotaryFee = (event) => {
    setSelectedState(event.target.value);
    let state = servicePriceData?.find(
      (item) => item.state == event.target.value
    );
    setFlatPrice(state?.price || 0);
    setTravelFee(0);
  };
  useEffect(() => {
    const getFlatPricesList = () => {
      getFlatprices().then((res) => {
        if (res.status == 200) {
          console.log(res.data.list);
          setFlatPriceData(res.data.list);
        }
      });
    };
    const getServicePriceByState = () => {
      getService(id).then((res) => {
        if (res.status === 200) {
          setServicePriceData(res.data.list.states);
        }
      });
    };
    getFlatPricesList();
    getServicePriceByState();
  }, []);

  return (
    <>
      <div class="bg-service">
        <div class="container">
          <div class="col-md-12">
            <h1 class="text text-white fw-700">Fill Details</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <a href="#" class="text-decoration-none text-white fw-700">
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active text-white fw-700"
                  aria-current="page"
                >
                  Fill Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {/* <!-- Form --> */}
      <div class="container text-black mt-5">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <img src={Icon5} alt="images" />
              <h4 class="fw-900">Oath Adminstration</h4>
              <div>
                <img src={Calender} alt="caldendar" />
                {moment(state.selectedDate).format("h:mm A, dddd,MMMM DD,YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Details --> */}
      <form onSubmit={handleSubmit(subnitForm)} class="service-form">
        <div class="container mt-5 text-black align-items-center">
          <div class="row">
            <div class="col-md-12">
              <h4 class="fw-900 mb-4">Enter Details</h4>
            </div>
            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="name" class="form-label ">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  {...register("name", { required: "Name is required." })}
                />
                {errors.name && (
                  <span className="err-msg">{errors.name.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="email" class="form-label ">
                  Email
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter valid email address.",
                    },
                  })}
                />
                {errors.email && (
                  <span className="err-msg">{errors.email.message}</span>
                )}
              </div>
            </div>

            <div class="col-md-2">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  Please select state
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <select
                  className="form-select"
                  {...register("state", {
                    required: "Please select the state",
                    validate: onChangeState,
                    onChange: (e) => handleNotaryFee(e),
                  })}
                >
                  <option value="">Select State</option>
                  {context.states &&
                    context.states.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}{" "}
                      </option>
                    ))}
                </select>
                {errors.state && (
                  <span className="err-msg">{errors.state.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  Please select City
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <select
                  className="form-select"
                  {...register("city", {
                    required: "Please select the city",
                    validate: onChangeCity,
                    onChange: (e) => handleTravelFee(e),
                  })}
                >
                  <option value="">Select City</option>
                  {context.cities &&
                    context.cities[selectedState]?.map(
                      (city) => (
                        <option key={city} value={city}>
                          {city}{" "}
                        </option>
                      )
                      // console.log('city',city)
                    )}
                </select>
                {errors.city && (
                  <span className="err-msg">{errors.city.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  Address the Notary will be traveling to?
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <Controller
                  control={control}
                  name="location"
                  rules={{ required: "Please select address." }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      class="form-control"
                      apiKey="AIzaSyDXHHQVzMU2AVAjN99gENu43J810uCCBwc"
                      options={{
                        types: [],
                        componentRestrictions: { country: "us" },
                      }}
                      onPlaceSelected={(place) => {
                        onChange(place);
                      }}
                    />
                  )}
                />
                {/* {errors.location && (
                  <span className="err-msg">{errors.location.message}</span>
                )} */}
              </div>
            </div>

            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <label for="singer" class="form-label d-block ">
                  Are you the person giving the sworn statement?
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="yes"
                    {...register("giving_sworn_statement", {
                      required: "Please select one of the option.",
                    })}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="no"
                    {...register("giving_sworn_statement", {
                      required: "Please select one of the option.",
                    })}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    No
                  </label>
                </div>
                {errors.giving_sworn_statement && (
                  <span className="err-msg">
                    {errors.giving_sworn_statement.message}
                  </span>
                )}
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="address" class="form-label ">
                  What language is the document(s) in?
                </label>
                {/* <input type="text" class="form-control" id="address" /> */}
                <select
                  className="form-select"
                  {...register("language", {
                    required: "Please select the language.",
                  })}
                >
                  <option value="">Select Language</option>
                  {context.languages &&
                    context.languages.map((language) => (
                      <option value={language.name}>{language.name} </option>
                    ))}
                </select>
                {errors.language && (
                  <span className="err-msg">{errors.language.message}</span>
                )}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4 pb-2">
                <label for="phone" class="form-label ">
                  If no, please state your relation to the person giving the
                  sworn statement
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  {...register("relation_with_giving_sworn")}
                />
              </div>
            </div>
          </div>
          <div class="mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              {...register("travelFeeCheckbox", {
                required: "Please accept this.",
              })}
            />
            <label class="form-check-label" for="exampleCheck1">
              ${travelFee} will be pre-authorized for travel fee and will be
              charged in addition to ${flatPrice} fee to administer oath/sworn
              statement.{" "}
              <Link style={{ color: "aqua" }} to="/pricing">
                See page for more details
              </Link>
            </label>
          </div>
          {errors.travelFeeCheckbox && (
            <span className="err-msg">{errors.travelFeeCheckbox.message}</span>
          )}
          <div class="col-xl-3 col-md-4">
            <button type="submit" class="btn btn-continue w-100 mt-3">
              Schedule Event
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default OathAdminstration;
