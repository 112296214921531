import React, { useState } from "react";
import { useForm } from "react-hook-form";

import building from "assests/images/building.svg";
import message from "assests/images/message.svg";
import telephone from "assests/images/telephone.svg";
import { createContactFrom } from "services/services";
import { toast } from "react-toastify";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      let response = await createContactFrom(data);
      console.log("respn schech", response);
      if (response.status === 201) {
        toast.success(response?.data?.message);
        reset();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="bg-contact">
        <div className="container">
          <div className="col-md-12">
            <h1 className="text text-white fw-700">Contact</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a
                    href="#"
                    className="text-decoration-none text-white fw-700"
                  >
                    Home
                  </a>
                </li>
                <li
                  className="breadcrumb-item active text-white fw-700"
                  aria-current="page"
                >
                  Contact
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container mt-5 text-black">
        <div className="row">
          <div className="col-xl-6 col-lg-8 col-md-9 mx-auto">
            <div className="text-center ">
              <h1 className="fw-900">
                {" "}
                <span className="color">Connect</span> with us!{" "}
              </h1>
            </div>
          </div>
        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-md-5">
            <div className="card text-center border-0 contact-card">
              <img
                src={message}
                alt="building"
                width="60"
                height="60px"
                className="mx-auto"
              />
              <h3 className="fw-700 mt-3">Email Address</h3>
              <p className="mb-0">hello@mobilenotaryx.ai</p>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card text-center border-0 contact-card">
              <img
                src={telephone}
                alt="building"
                width="60"
                height="60px"
                className="mx-auto"
              />
              <h3 className="fw-700 mt-3">Telephone</h3>
              <p className="mb-0">+1 (303) 565-7120</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-black">
            <p className="small mb-0 fw-700">Please fill form</p>
            <h2 className="fw-700 mb-4">Do you have any Questions?</h2>

            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="text-danger">Name is required</span>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-danger">Email is required</span>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="subject" className="form-label">
                  Subject (Topic)
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("subject", { required: true })}
                />
                {errors.subject && (
                  <span className="text-danger">Subject is required</span>
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="message" className="form-label d-block">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="5"
                  className="form-control"
                  {...register("message", { required: true })}
                ></textarea>
                {errors.message && (
                  <span className="text-danger">Message is required</span>
                )}
              </div>

              <div className="">
                <button type="submit" className="btn btn-book px-5 py-3">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
