import { useEffect, useState } from "react";
import { getStaticContent } from "services/services";

const Privacy = () => {
    const [content, setContent]= useState('')
    useEffect(() => {
        const getContent=()=>{
            getStaticContent(3).then(response=>{
                if(response.status==200){
                    setContent(response.data.data.content)
                }
            })
        }
        getContent()
    }, []);
    return (
        <>
            <div class="bg-privacy">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">Privacy</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#" class="text-decoration-none text-white fw-700">Home</a></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">Privacy</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container mt-5 text-black">
                {/* <div class="row">
                    <div class="col-md-12">
                        <h3 class="fw-900 mb-4"> Please read this privacy in their entirely before using or receiving any features (as defined below)
                            (THE ‘MOBILE NOTARY X’). </h3>

                        <ul>
                            <li class="mb-3 fw-600">Vivamus pretium tellus eget mi mattis ut accumsan nunc vehicula. Donec mattis ex et aliquam aliquam.
                                Maecenas at luctus augue. Donec nec leo ac libero pretium tincidunt</li>
                            <li class="mb-3 fw-600">Aenean quis purus auctor rhoncus est non dictum arcu maximus integer interdum eget</li>
                            <li class="mb-3 fw-600">Donec dolor magna suscipit in magna dignissim porttitor hendrerit gravida felis</li>
                            <li class="mb-3 fw-600">Aliquam varius neque commodo purus vulputate pharetra bibendum in ante id ornare</li>
                            <li class="mb-3 fw-600">Morbi dui lectus lobortis sit amet felis nec suscipit imperdiet sapien. Proin semper ultrices ex a sodales
                                purus vehicula. Nunc gravida ultrices felis eget faucibus praesent aliquet</li>

                            <li class="mb-3 fw-600">Aenean quis purus auctor rhoncus est non dictum arcu maximus integer interdum eget</li>
                            <li class="mb-3 fw-600">Donec dolor magna suscipit in magna dignissim porttitor hendrerit gravida felis</li>
                            <li class="mb-3 fw-600">Aliquam varius neque commodo purus vulputate pharetra bibendum in ante id ornare</li>
                            <li class="mb-3 fw-600">Morbi dui lectus lobortis sit amet felis nec suscipit imperdiet sapien. Proin semper ultrices ex a sodales
                                purus vehicula. Nunc gravida ultrices felis eget faucibus praesent aliquet</li>
                            <li class="mb-3 fw-600">Morbi dui lectus lobortis sit amet felis nec suscipit imperdiet sapien. Proin semper ultrices ex a sodales
                                purus vehicula. Nunc gravida ultrices felis eget faucibus praesent aliquet</li>
                        </ul>

                        <p class="fw-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua Ut enim ad minim veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet
                            , consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim
                            veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim veniam quis nostrud
                            architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim veniam quis nostrud architecto beatae vitae
                            dicta sunt explicabo. </p>

                        <p class="fw-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua Ut enim ad minim veniam quis nostrud architecto beatae vitae dicta sunt</p>
                    </div>


                </div> */}

                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </>
    );
}

export default Privacy;