import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getProfile, updateProfile } from "services/services";

const EditProfile = () => {
    const{register,handleSubmit,formState:{errors},setValue} = useForm()

    const editProfile = (data)=>{
        updateProfile(data).then(response=>{
            if(response.status==200){
                toast.success('Profile updated successfully.')
            }
        }).catch(err=>{
            toast.error(err.response.data.message)
        })
    }
    useEffect(()=>{
        const myProfile = ()=>{
            getProfile().then(response=>{
                if(response.status==200){
                    setValue('name',response.data.data.name)
                    setValue('email',response.data.data.email)
                    setValue('phoneNumber',response.data.data.phoneNumber)
                }
            }).catch(err=>{
                toast.error(err.response.data.message)
            })
        }
        myProfile()
    },[])

    return (
        <>
            <div class="tab-pane fade show active" id="v-1">
                <div class="card-header theme-color border-0 py-3">
                    <span class="text-white fw-bolder ms-2">Edit Profile</span>
                </div>
                <form class="contact-form p-4" onSubmit={handleSubmit(editProfile)}>
                    
                    <div class="mb-4">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" class="form-control" name="name" {...register('name', { required: "Name is required." })} />
                        {errors.name && <span className='err-msg'>{errors.name.message}</span>}
                    </div>
                    <div class="mb-4">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control" name="email" disabled {...register('email', { required: "Email is required", pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Please enter valid email address." } })} />
                        {/* {errors.email && <span className='err-msg'>{errors.email.message}</span>} */}
                    </div>
                    <div class="mb-4">
                        <label for="mobile" class="form-label">Mobile Number</label>
                        <input type="text" class="form-control" name="mobile" {...register('phoneNumber', { required: "Mobile Number is required.", pattern: { value: /^\+?[1-9][0-9]{7,14}$/, message: "Invalid number" } })}/>
                        {errors.phoneNumber && <span className='err-msg'>{errors.phoneNumber.message}</span>}
                    </div>
                    <button class="btn btn-book py-3 px-md-5">Update</button>
                </form>
            </div>
        </>
    );
}

export default EditProfile;