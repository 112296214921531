import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { verifyForgetPass } from 'services/services';
import { toast } from "react-toastify";


const ForgotVerification = () => {
    //const [forgotVerificationCode] = useForgotVerificationPostMutation();
    //const [forgotResendCode] = useForgotResendOtpMutation();
    const navigate = useNavigate()
    const [inputs, setInputs] = useState(['', '', '', '']);

    const handleInputChange = (index, value) => {
        // Validate input to allow only digits
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            // Focus on the next input field if available
            if (index < 3 && value.length === 1) {
                document.getElementById(`input-${index + 1}`).focus();
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Join the input values to get the verification code
        const verificationCode = inputs.join('');

        let user = localStorage.getItem('emailForgetPass')
        if (verificationCode.length !== 4) {
            return toast.error("Please Enter verification code.");
        }
        let verificationDetail = {
            email: user,
            otp: verificationCode,
            type:"forgetPass"
        }
       
        verifyForgetPass(verificationDetail)
          .then((res) => {
           
            if (res?.status==200) {
              toast.success(res?.data?.message);
              setInputs(['', '', '', ''])
              navigate("/reset-password")
            }
          })
          .catch((err) => {
            if (err.response?.status == 400) {
                toast.error(err.response.data.message)
            }
           
          });
    };

    const forgotResendHandler = (e) => {
        e.preventDefault();
        let user = localStorage.getItem('email')
        let verificationDetail = {
            email: user,
        }
        // forgotResendCode(verificationDetail)
        //   .unwrap()
        //   .then((res) => {
        //     toast.success(res?.message);
        //   })
        //   .catch((err) => {
        //     toast.error(err?.data?.message);
        //   });


    }

    const submitHandler = () => {
       
    }



    return (
        <div className="section">
            <div className="height-100">
                <div className="top">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                {/* <Link to={'/forgot-password'}>back</Link> */}
                                <h4 className="design-color fw-bold mt-4 text-center">Verification Code</h4>
                                <p className="mt-2 text-muted mb-0 text-center" >We’ll send you a verification code</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <form onSubmit={handleSubmit} className="form-login otp">
                                    <div className="row justify-content-center my-3">
                                        <div className="col-md-4 col-lg-3">
                                            <div className="d-flex justify-content-between">
                                                {inputs.map((value, index) => (
                                                    <div key={index} className="input-width">
                                                        <input
                                                            type="number"
                                                            className="no-spinner form-control py-3 text-center"
                                                            value={value}
                                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                                            id={`input-${index}`}
                                                            maxLength={1}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-5">

                                            <div class="mx-xl-5 px-md-5 my-5">
                                                <button class="btn btn-book w-100 py-3" >Submit</button>
                                            </div>
                                            {/* <h5 className="mt-2 fw-normal text-center">
                                                <span className="text-black">Didn’t receive OTP?</span>
                                                <a className="design-color" onClick={forgotResendHandler}>Resend Code</a>
                                            </h5> */}
                                        </div>
                                    </div>

                                </form>


                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img src={buttomLine} alt="line" className="line img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
            </div>
        </div>
    );
};



export default ForgotVerification