import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { changePassword } from "services/services";

const ChangePassword = () => {
    const {register,handleSubmit, formState:{errors},watch,reset}= useForm()

    const updatePassword = (data)=>{
        delete data['confirmPassword']
        changePassword(data).then(response=>{
            if(response.status==200){
                reset()
                toast.success(response.data.message)
            }
        }).catch(err=>{
            toast.error(err.response.data.message)
        })
    }
    return (
        <>
            <div class="tab-pane fade" id="v-4">
                <div class="card-header border-0 theme-color py-3">
                    <span class="text-white fw-bolder ms-2">Change Password</span>
                </div>
                <form class="contact-form p-4" onSubmit={handleSubmit(updatePassword)}>
                    <div class="mb-4">
                        <label for="old-password" class="form-label">Old Password</label>
                        <input type="password" class="form-control" name="old-password" {...register('oldPassword',{required:"Please enter old password."})}/>
                        {errors.oldPassword && <span className='err-msg'>{errors.oldPassword.message}</span>}
                    </div>
                    <div class="mb-4">
                        <label for="new-password" class="form-label">New Password</label>
                        <input type="password" class="form-control" name="new-password" {...register('password',{required:"Please enter new password."})}/>
                        {errors.password && <span className='err-msg'>{errors.password.message}</span>}
                    </div>
                    <div class="mb-4">
                        <label for="confirm" class="form-label">Confirm New Password</label>
                        <input type="password" class="form-control" name="confirm" {...register('confirmPassword',{required:"Please enter confirm password.",validate:value=>value===watch('password')||"Password doesn;t match."})}/>
                        {errors.confirmPassword && <span className='err-msg'>{errors.confirmPassword.message}</span>}
                    </div>
                    <button type="submit" className="btn btn-book py-3 px-md-5">Update</button>
                </form>
            </div>
        </>
    );
}

export default ChangePassword;