import { useEffect, useState } from "react";
import { getStaticContent } from "services/services";

const Terms = () => {

    const [content, setContent]= useState('')
    useEffect(() => {
        const getContent=()=>{
            getStaticContent(1).then(response=>{
                if(response.status==200){
                    setContent(response.data.data.content)
                }
            })
        }
        getContent()
    }, []);

    return (
        <>
            <div class="bg-privacy">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">Terms & Conditions</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#" class="text-decoration-none text-white fw-700">Home</a></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">Terms & Conditions</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <!-- Section --> */}

            <div class="container mt-5 text-black">
                {/* <div class="row">
                    <div class="col-md-12">
                        <h3 class="fw-900 mb-4"> Please read this T&C in their entirely before using or receiving any features (as defined below)
                            (THE ‘MOBILE NOTARY X’).
                        </h3>
                        <p class="fw-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua Ut enim ad minim veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet
                            , consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim
                            veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim veniam quis nostrud
                            architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim veniam quis nostrud architecto beatae vitae
                            dicta sunt explicabo.
                        </p>
                        <h5 class="fw-900">1. Collection of Information</h5>
                        <p class="fw-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua Ut enim ad minim veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet
                            , consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim
                            veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad.
                        </p>
                        <h5 class="fw-900">2. Collection of Information</h5>
                        <p class="fw-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua Ut enim ad minim veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet
                            , consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad minim
                            veniam quis nostrud architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua Ut enim ad.
                        </p>
                        <p class="fw-600">Donec sodales, nibh vel tristique aliquet, nisi libero suscipit diam, sed tempus ante nulla ut purus. Donec dolor magna
                            , suscipit in magna dignissim, porttitor hendrerit diam. Nunc gravida ultrices felis sodalesrerit diam.
                        </p>
                    </div>
                </div> */}
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </>
    );
}

export default Terms;