import AppContext from "context/appContext";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FaComments } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { convertToLocalTime } from "services/dateService";
import { getMyJobs } from "services/services";

const Mybookings = () => {
  const context = useContext(AppContext);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const myJobs = () => {
      getMyJobs()
        .then((response) => {
          if (response.status == 200) {
            setJobs(response.data.data);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    };
    myJobs();
  }, []);

  const goToChat = (assigned) => {
    console.log("assigned", assigned);
    context.setAssigned(assigned);
    navigate("/chat");
  };
  return (
    <>
      <div class="tab-pane fade" id="v-5">
        <div class="card-header border-0 theme-color py-3">
          <span class="text-white fw-bolder ms-2">My Bookings</span>
        </div>
        <div style={{ height: "500px", overflow: "auto" }} class="container">
          <div class="row py-4 px-2">
            <div class="col-md-12">
              <div class="card mb-4 border-0">
                {jobs?.length !== 0 &&
                  jobs.map((job) => (
                    <div class="card-body">
                      <h5 class="card-title fw-900 m-img">
                        {job?.service?.service_name}
                      </h5>

                      <p class="card-text">
                        <span>
                          <i class="fa fa-calendar"></i>
                          {moment(
                            convertToLocalTime(job?.schedule_date)
                          ).format("HH:mm, dddd,MMMM DD,YYYY")}
                        </span>
                      </p>
                      <div class="clearfix">
                        <div class="float-start">
                          <div class="d-md-flex align-items-center">
                            <button
                              onClick={() =>
                                navigate(`/job-details/${job?._id}`)
                              }
                              class="btn btn-book"
                            >
                              See Details
                            </button>
                            <p
                              class={`${
                                job?.status == "cancelled"
                                  ? "text-danger"
                                  : job?.status == "not_accepted"
                                  ? "text-muted"
                                  : job?.status == "accepted"
                                  ? "text-success"
                                  : job?.status == "pending"
                                  ? "text-warning"
                                  : job?.status == "not_completed"
                                  ? "text-muted"
                                  : job?.status == "completed"
                                  ? "text-success"
                                  : ""
                              } fw-bolder mb-0 mt-3 mt-md-0 ms-md-3 ms-1`}
                            >
                              {job?.status == "cancelled"
                                ? "Cancelled"
                                : job?.status == "not_accepted"
                                ? "Not Accepted"
                                : job?.status == "accepted"
                                ? "Accepted"
                                : job?.status == "not_completed"
                                ? "Not Completed"
                                : job?.status == "completed"
                                ? "Completed"
                                : job?.status == "pending"
                                ? "Pending"
                                : ""}
                            </p>
                          </div>
                        </div>

                        {job?.assigned_to && (
                          <div class="float-end">
                            <a
                              class="chat-font"
                              onClick={() => goToChat(job?.assigned_to._id)}
                            >
                              <FaComments />
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mybookings;
