import { io } from "socket.io-client";

// export const socket = io("http://localhost:5003",{

//         auth:{token:localStorage.getItem('token')}

// })
 //export const socket = io("https://mobilenotary-api.alcax.com")
  //export const socket = io("https://socket.mobilenotaryx.ai:5004/", {
 // auth: { token: localStorage.getItem("token") },

  export const socket = io("https://socket.mobilenotaryx.ai:5004");
