import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { deleteAddedCard } from "services/services"

export const DeleteCard = ( stripeCardId, Cards) => {

    Swal.fire({
        title: `Do you want to delete the card?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#FF4D67",
        // cancelButtonColor:"#e7e7e7"
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            {
                
                deleteAddedCard(stripeCardId).then(res => {
                        if (res.status == 200) {
                            Swal.fire('', "Card Deleted successfully.", 'success')
                            Cards()
                        }
                    }).catch(err => {
                        if (err.response.status == 401) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                        if (err.response.status == 400) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                    })
            }
        } else if (result.isDenied) {
            Swal.close()
        }
    })
}

