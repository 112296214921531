import defaultUser from "assests/images/download.jpeg";
import bgService from "assests/images/bg-service.png";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "context/appContext";
import moment from "moment";
import { useForm } from "react-hook-form";
import { addNewMessage, getMessagesOfChat } from "services/services";
import { sendMessage } from "utils/socketEvents";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaArrowUp } from "react-icons/fa6";
import { toast } from "react-toastify";
import NoMsgImg from "assests/images/no-message 1.png";
import { scrollToBottom } from "utils/scrollInChat";
import ReactTimeAgo from "react-time-ago";
const ChatMessages = ({
  messages,
  userName,
  userAvatar,
  scrollRef,
  newChat,
}) => {
  const context = useContext(AppContext);

  const { register, handleSubmit, reset } = useForm();
  const [chatMessages, setChatMessages] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(2);
  // console.log('msgs', messages);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [stateChange, setStateChange] = useState(true);

  const sendMsg = (data) => {
    console.log(newChat);
    if (messages.length == 0 && !newChat) {
      toast.error("No Chat selected.");
    } else {
      let chatId = messages[0]?.chatId;
      if (data.msg !== "") {
        const formData = new FormData();
        formData.append("chatId", chatId || newChat);
        formData.append("content", data.msg);
        addNewMessage(formData).then((res) => {
          if (res.status == 201) {
            reset();
            console.log("Hello----->",res.data.data)
            sendMessage(res?.data?.data);
          }
        });
      }
    }
  };

  const fetchMoreData = () => {
    console.log("length", chatMessages.length, totalCount);
    let chatId = messages[0].chatId;
    getMessagesOfChat(chatId, page).then((res) => {
      if (res.status == 200) {
        if (res.data.list.length == 0) return setShowLoadMore(false);
        setChatMessages((prevMessages) => [...prevMessages, ...res.data.list]);
        setPage((prevPage) => prevPage + 1);
        setTotalCount(res.data.meta.totalcount);
      }
    });
  };

  useEffect(() => {
    console.log("yes in this===========>i am ");
    setChatMessages(messages);
    setPage(2);
    setTotalCount("");
    setShowLoadMore(true);
    setStateChange(!stateChange);
  }, [messages]);

  useEffect(() => {
    scrollToBottom(scrollRef);
  }, [stateChange]);

  return (
    <>
      <div class="col-lg-8 col-md-7 Border-1">
        {messages?.length !== 0 ? (
          <div class="tab-content" id="v-pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
              tabindex="0"
            >
              <div class="row align-items-center Border-2 py-3">
                <div class="col-1">
                  <img
                    src={
                      userAvatar ? context.img_uri + userAvatar : defaultUser
                    }
                    alt="images"
                    class="rounded-circle imgFit"
                    width="50"
                    height="50"
                  />
                </div>
                <div class="col-10">
                  <h5 class="fw-900 mb-0">{userName}</h5>
                </div>
              </div>
              <section class="msger" id="scrollableEle">
                <main class="msger-chat" ref={scrollRef}>
                  {showLoadMore && (
                    <div className="text-center">
                      <button
                        className="btn btn-loadMore"
                        onClick={fetchMoreData}
                      >
                        <FaArrowUp className="text-white me-1" />
                        Load More
                      </button>
                    </div>
                  )}
                  {chatMessages?.length !== 0 &&
                    chatMessages
                      .slice()
                      .reverse()
                      .map((message) => (
                        <>
                          {message.sender._id !== context.userId ? (
                            <div class="msg left-msg mb-5">
                              <div>
                                <img
                                  className="img-fluid img-fix"
                                  src={
                                    message.sender.avatar
                                      ? context.img_uri + message.sender.avatar
                                      : defaultUser
                                  }
                                  alt="image"
                                  class="msg-img"
                                />
                              </div>
                              <div class="msg-bubble">
                                <div class="msg-info mb-0">
                                  <div class="msg-info-time">
                                    <ReactTimeAgo
                                      date={message.createdAt}
                                      locale="en-US"
                                    />
                                  </div>
                                </div>
                                {message.sender._id != context.userId &&
                                message.content[0].type == "text" ? (
                                  <div class="msg-text">
                                    {message.sender._id != context.userId &&
                                      message.content[0].content}
                                  </div>
                                ) : (
                                  <div class="msg-text">
                                    <img
                                      className="img-fluid"
                                      src={
                                        message.sender._id != context.userId &&
                                        context.img_uri +
                                          message.content[0].content
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div class="msg right-msg">
                              <div>
                                <img
                                  src={
                                    message.sender.avatar
                                      ? context.img_uri + message.sender.avatar
                                      : defaultUser
                                  }
                                  alt="image"
                                  class="msg-img"
                                />
                              </div>
                              <div class="msg-bubble">
                                <div class="msg-info">
                                  <div class="msg-info-time">
                                    <ReactTimeAgo
                                      date={message.createdAt}
                                      locale="en-US"
                                    />
                                  </div>
                                </div>
                                {message.sender._id == context.userId &&
                                message.content[0].type == "text" ? (
                                  <div class="msg-text">
                                    {message.sender._id == context.userId &&
                                      message.content[0].content}
                                  </div>
                                ) : (
                                  <div class="msg-text">
                                    <img
                                      src={
                                        message.sender._id == context.userId &&
                                        context.img_uri +
                                          message.content[0].content
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  {/* </InfiniteScroll> */}
                </main>
              </section>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
              tabindex="0"
            >
              <div class="row align-items-center Border-2 py-3">
                <div class="col-1">
                  <img
                    src={defaultUser}
                    alt="images"
                    class="rounded-circle"
                    width="50"
                    height="50"
                  />
                </div>
                <div class="col-10">
                  <h5 class="fw-900 mb-0">Shane Christanio 2</h5>
                </div>
              </div>
              <section class="msger">
                <main class="msger-chat">
                  <div class="msg left-msg mb-5">
                    <div>
                      <img
                        src="images/bg-chat.png"
                        alt="image"
                        class="msg-img"
                      />
                    </div>
                    <div class="msg-bubble">
                      <div class="msg-info">
                        <div class="msg-info-time">12:45</div>
                      </div>
                      <div class="msg-text">
                        Hi, welcome to SimpleChat! Go ahead and send me a
                        message. 😄
                      </div>
                    </div>
                  </div>
                  <div class="msg right-msg">
                    <div>
                      <img
                        src="images/bg-service.png"
                        alt="image"
                        class="msg-img"
                      />
                    </div>
                    <div class="msg-bubble">
                      <div class="msg-info">
                        <div class="msg-info-time">12:46</div>
                      </div>
                      <div class="msg-text">
                        You can change your name in JS section!
                      </div>
                    </div>
                  </div>
                </main>
              </section>
            </div>
          </div>
        ) : (
          <div className="msger d-flex align-items-center justify-content-center">
            <img style={{ width: "100px" }} src={NoMsgImg} />
            <p style={{ fontSize: "20px" }}>No chat here</p>
          </div>
        )}
        <form class="input-group send-text" onSubmit={handleSubmit(sendMsg)}>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your message..."
            {...register("msg")}
          />
          <button type="submit" class=" btn btn-book ms-3">
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default ChatMessages;
