import googleIcon from "assests/images/google.svg";
import faceBookIcon from "assests/images/face.svg";
import appleIcon from "assests/images/apple.svg";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { socialSignin, userSignup } from "services/services";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import AppContext from "context/appContext";
import dbaIcon from "../assests/images/Vector.png"
import ThanksBusniessForm from "./ThanksBusniessForm";
import LoadingAction from "./LoadingAction";
import { BiSolidHide,BiShow } from "react-icons/bi";

const Signup = () => {
  const context = useContext(AppContext);
  const [avatar, setAvatar] = useState("");
  const [image, setImage] = useState("");
  const [imageDba, setImageDba] = useState("");
  const [imageEin, setImagEin] = useState("");
  console.log(context.googleRef);
  const navigate = useNavigate();
  const [userType, setUserType] = useState("");
  const [userTypeErr, setUserTypeErr] = useState("");
  const [show ,setShow] = useState(false)
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setFocus,
  } = useForm();

  const submitForm = (data) => {
    if (!userType) {
      setUserTypeErr("User Type is required");
      return;
    } else {
      setUserTypeErr(""); // Clear error if userType is selected
    }

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("password", data.password);

    formData.append("serviceType", userType);
    formData.append("dbaDoc", imageDba);
    formData.append("ein", imageEin);

    if (context.isSocial) {
      formData.append("socialType", context.socialType);
      formData.append("socialId", context.socialData.sub);
      formData.delete("password");
    }
    formData.append("role", "user");
    formData.append("avatar", image);
    console.log("fom-data", formData);
    setLoading(true)
    userSignup(formData)
      .then((res) => {
        if (res.status == 201) {
          setLoading(false)
          toast.success(res.data.message);
          context.setSocialData(null);
          context.setIsSocial(false);
          if(res?.data?.data?.serviceType=="business"){
            setShow(true)
          }else{
            navigate("/login");
          }
         
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status == 400) {
          toast.error(err.response.data.message);
        }
      });
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const imageObj = (e) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
    let file_url = URL.createObjectURL(e.target.files[0]);
    setAvatar(file_url);

  };

  const imageObjDba = (e) =>{
    setImageDba(e.target.files[0])
  }

  const imageObjEin = (e) =>{
    setImagEin(e.target.files[0])
  }

  const handleClose = () =>{
    setShow(false)
  }

  useEffect(() => {
    console.log(context.socialData == null);
    setValue("name", context.socialData?.name);
    setValue("email", context.socialData?.email);
    setFocus("phoneNumber");
  }, [context]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div class="bg-sign">
        <div class="container">
          <div class="col-md-12">
            <h1 class="text text-white fw-700">Sign up</h1>
            <nav aria-label="breadcrumb">  
              <ol class="breadcrumb justify-content-center">
                <li class="breadcrumb-item">
                  <Link to="/login" class="text-decoration-none text-white fw-700">
                    Home
                  </Link>
                </li>
                <li
                  class="breadcrumb-item active text-white fw-700"
                  aria-current="page"
                >
                  Sign up
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
     {show && (<ThanksBusniessForm show={show}  handleClose={handleClose}/>)} 
    {loading && <LoadingAction /> } 
      <div class="container mt-5 text-black">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <h2 class="text-center fw-900">Create Account</h2>
            <p class="text-center fw-600">
              Already have an account?{" "}
              <Link to="/login" class="color text-decoration-none fw-900">
                Sign in
              </Link>
            </p>
            <form class="contact-form mt-5" onSubmit={handleSubmit(submitForm)}>
           
              <div class="avatar-upload text-center">
                <div class="avatar-edit">
                  <input
                    type="file"
                    id="ProfilePictureWeb"
                    name="ProfilePictureWeb"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => imageObj(e)}
                  />
                  <label for="ProfilePictureWeb"></label>
                </div>

                <div class="avatar-preview">
                  <img
                    src={avatar || require("../assests/images/user.png")}
                    class="img-fluid"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="userType" className="form-label">
                  User Type
                </label>
                <select
                  className="form-select"
                  name="userType"
                  value={userType}
                  onChange={handleUserTypeChange}
                >
                  <option value="">Select User Type</option>
                  <option value="individual">Individual</option>
                  <option value="business">Business</option>
                </select>
                {userTypeErr && <span className="err-msg">{userTypeErr}</span>}
              </div>
              <div class="mb-4">
                <label htmlFor="name" class="form-label">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  {...register("name", { required: "Name is required." })}
                />
                {errors.name && (
                  <span className="err-msg">{errors.name.message}</span>
                )}
              </div>
              <div class="mb-4">
                <label htmlFor="email" class="form-label">
                  Email
                </label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter valid email address.",
                    },
                  })}
                />
                {errors.email && (
                  <span className="err-msg">{errors.email.message}</span>
                )}
              </div>
              <div class="mb-4">
                <label htmlFor="mobile" class="form-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="mobile"
                  {...register("phoneNumber", {
                    required: "Mobile Number is required.",
                    pattern: {
                      value: /^\+?[1-9][0-9]{7,14}$/,
                      message: "Invalid number",
                    },
                  })}
                />
                {errors.phoneNumber && (
                  <span className="err-msg">{errors.phoneNumber.message}</span>
                )}
              </div>
              {context.socialData == null && (
                <div class="mb-4 position-relative">
                  <label htmlFor="password" class="form-label">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control"
                    name="password"
                    minLength={6}
                    {...register("password", {
                      required: "Password is required.",
                    })}
                  />
                  <div className='password-hide-show' onClick={togglePasswordVisibility}>{showPassword ? <BiShow /> : <BiSolidHide />} </div>
                  {errors.password && (
                    <span className="err-msg">{errors.password.message}</span>
                  )}
                </div>
              )}
              {userType == "business" && (
                <>

                  <div className="mb-4">
                    <div class="file-upload">
                      <label htmlFor="dbaDoc" class="form-label">
                        DBA Document
                      </label>
                      <input
                        type="file"
                        id="dbaDoc"
                        className="file-input"
                        {...register("dbaDoc", {
                          required: "DBA Document is required.",
                        })}
            
                        onChange={(e) => imageObjDba(e)}
                      />
                      <label
                        htmlFor="dbaDoc"
                        className="file-label form-control"
                      >
                        <img
                          src={dbaIcon}
                          alt="Upload Icon"
                          width='20px'
                          className="file-icon"
                        />
                        {imageDba?.name?imageDba?.name:"Choose file"}
                      </label>
                      {errors.dbaDoc && (
                        <span className="err-msg">{errors.dbaDoc.message}</span>
                      )}
                    </div>
                  </div>

                  <div class="mb-4">
                  <div class="file-upload">
                    <label htmlFor="dbaDoc" class="form-label">
                      Employer Identification Number Letter(EIN)
                    </label>
                    <input
                        type="file"
                        id="ein"
                        className="file-input"
                        {...register("ein", {
                          required: "EIN is required",
                        })}
                        onChange={(e) => imageObjEin(e)}
                      />
                      <label
                        htmlFor="ein"
                        className="file-label form-control"
                      >
                        <img
                          src={dbaIcon}
                          alt="Upload Icon"
                          className="file-icon"
                        />
                         {imageEin?.name?imageEin?.name:"Choose file"} 
                      </label>
                      {errors.ein && (
                        <span className="err-msg">{errors.ein.message}</span>
                      )}
                      </div>
                  </div>
                </>
              )}
              <div class="mx-xl-5 px-md-5 my-5">
                <button class="btn btn-book w-100 py-3">{(userType == "individual" || userType == "") ? "Sign up" : "Sign up As Business"}</button>
              </div>
              

              
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
