import footerLogo from 'assests/images/footer-logo.svg'
import facebookIcon from 'assests/images/facebook.svg'
import instaIcon from 'assests/images/insta.svg'
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
            <footer class="py-5 text-white mt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3 col-lg-12">
                            <img src={footerLogo} alt="logo" class="footer-logo" />
                            <p class="mt-3"> Our mission is to redefine the notary experience by prioritizing your convenience without compromising on professionalism, efficiency or quality.</p>
                        </div>
                        <div class="col-xl-3 col-md-4">
                            <h4 class="mb-3 fw-bolder m-img">Quick Links</h4>
                            <ul class="list-unstyled">
                                <li class="mb-3"><Link to='/services' class="text-decoration-none text-white">Services</Link></li>
                                <li class="mb-3"><Link to='/about-us' class="text-decoration-none text-white">About</Link></li>
                                <li class="mb-3"><Link to='/contact-us' class="text-decoration-none text-white">Contact</Link></li>
                            </ul>
                        </div>
                        <div class="col-xl-3 col-md-4">
                            <h4 class="mb-3 fw-bolder m-img">Support</h4>
                            <ul class="list-unstyled">
                                <li class="mb-3"><Link to='/faqs' class="text-decoration-none text-white">Faq's</Link></li>
                                <li class="mb-3"><Link to='/privacy' class="text-decoration-none text-white">Privacy</Link></li>
                                <li class="mb-3"><Link to='/terms' class="text-decoration-none text-white">Terms & Conditions</Link></li>
                            </ul>
                        </div>
                        <div class="col-xl-3 col-md-4">
                            <h4 class="mb-3 fw-bolder m-img">Our Social Network</h4>
                            <ul class="list-unstyled list-inline">
                                <li class="mb-3 list-inline-item me-4"><a href="https://www.facebook.com/mobilenotaryxllc?mibextid=ZbWKwL" target='_blank' class="text-decoration-none text-white">
                                    <img src={facebookIcon} alt="facebook" />
                                </a></li>
                                <li class="mb-3 list-inline-item"><a href="https://www.instagram.com/mobile.notary.x?igshid=MmVlMjlkMTBhMg==" target="_blank" class="text-decoration-none text-white">
                                    <img src={instaIcon} alt="insta" />
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/* copyright section */}
            <div class="bg-black">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="text-white text-center py-4 mb-0">Copyright © Mobile Notary X</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;