
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as AppRouter } from "react-router-dom";
import AppState from './context/appState';
const root = ReactDOM.createRoot(document.getElementById('root'));


TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(ru)

root.render(
  // <React.StrictMode>
  <AppState>
    <AppRouter>
      <App />
    </AppRouter>
  </AppState>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
