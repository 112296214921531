import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getJobDetails } from "services/services";

const JobDetails = () => {
  let { id } = useParams();
  const [jobDetail, setJobDetail] = useState("");
  useEffect(() => {
    const getJob = () => {
      getJobDetails(id).then((res) => {
        if (res.status == 200) {
          console.log("job", res.data.data);
          setJobDetail(res.data.data);
        }
      });
    };
    getJob();
  }, []);
  return (
    <>
      <form action="" class="service-form">
        <div class="container mt-5 text-black align-items-center">
          <div class="row">
            {/* <a href="" class="text-decoration-none text-muted mb-3"><i class="fa fa-arrow-left h6"></i> Back</a> */}
            <div class="col-md-12">
              <h5 class="mb-2 small text-center text-muted">View Details</h5>
              <h3 class="fw-900 text-center mb-5 color">
                Event Name: <span>{jobDetail.service?.service_name}</span>
              </h3>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <div class="form-label small tex-muted">Name</div>
                <div class="fw-bolder h5">{jobDetail.name}</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <div class="form-label small tex-muted">Email</div>
                <div class="fw-bolder h5">{jobDetail.email}</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <div class="form-label small tex-muted">
                  Address the Notary will be traveling to?
                </div>
                <div class="fw-bolder h5">{jobDetail.location?.name}</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <div class="form-label small tex-muted">State</div>
                <div class="fw-bolder h5">{jobDetail.state}</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4 pb-2">
                <div class="form-label small tex-muted">City</div>
                <div class="fw-bolder h5">{jobDetail.city}</div>
              </div>
            </div>
            {jobDetail.service?.service_name == "Standard Notarization" && (
              <>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">Phone Number</div>
                    <div class="fw-bolder h5">{jobDetail.phoneNumber}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Are you the singer?
                    </div>
                    <div class="fw-bolder h5">{jobDetail.are_you_signer}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      If no, please state your relationship to the signer?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.relation_with_signer}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Have you reviewed our pricing and cancellation disclaimers
                      structure on the website?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.reviewed_our_pricing_structure}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Are the documents related to a Real Estate Transaction?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.docs_realated_to_real_estate_transaction}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Please provide the type/name of document(s) needing to be
                      notarized.
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.doc_need_to_be_notarized}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Does the person needing the notarization have a valid, NOT
                      expired government issued ID such as Drivers License or
                      Passport? (This is needed to perform the notarization
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.does_person_need_valid_govt_id}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Does the document being notarized require additional
                      witnesses not including the notary?
                    </div>
                    <div class="fw-bolder h5">{jobDetail.required_witness}</div>
                  </div>
                </div>
              </>
            )}
            {jobDetail.service?.service_name == "Real Estate Transaction" && (
              <>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Are you the singer?
                    </div>
                    <div class="fw-bolder h5">{jobDetail.are_you_signer}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      If no, please state your relationship to the signer?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.relation_with_signer}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      What type of Real Estate Transaction is this?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.type_of_real_estate_transaction}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Have you reviewed our pricing and cancellation disclaimers
                      structure on the website?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.reviewed_our_pricing_structure}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Does the document being notarized require additional
                      witnesses not including the notary?
                    </div>
                    <div class="fw-bolder h5">{jobDetail.required_witness}</div>
                  </div>
                </div>
              </>
            )}

            {/* OAuth  */}
            {jobDetail.service?.service_name ==
              "Oath Administration-Sworn Statement" && (
              <>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Are you the person giving the sworn statement?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.are_you_giving_sworn_statement}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      If no, please state your relation to the person giving the
                      sworn statement
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.relation_with_person_giving_sworn_statement}
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* i-9 */}
            {jobDetail.service?.service_name == "I-9 Verification" && (
              <>
                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Does the signer have the proper original identification
                      found in the right section on the I-9 Verification form?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.have_the_proper_original_identification}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="mb-4 pb-2">
                    <div class="form-label small tex-muted">
                      Have you reviewed our pricing and cancellation disclaimers
                      structure on the website?
                    </div>
                    <div class="fw-bolder h5">
                      {jobDetail.reviewed_our_pricing_structure}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default JobDetails;
