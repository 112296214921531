import logo from 'assests/images/logo.svg'
import userIcon from 'assests/images/usrname.svg'
import AppContext from 'context/appContext';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate()
    const context = useContext(AppContext)

    const closeNavBar = ()=>{
        let ele = document.getElementById('navbarSupportedContent')
        ele.classList.remove('show')
    }
    
    return (
        <>
            <nav class="navbar navbar-expand-lg notary-navbar sticky-top bg-white" >
                <div class="container">

                    <Link class="navbar-brand" to="/home">
                        <img src={logo} alt="logo" />
                    </Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id='navbarSupportedContent'>
                        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li class="nav-item" onClick={closeNavBar}>
                                <Link class="nav-link" to='/home'>Home</Link>
                            </li>
                            <li class="nav-item" onClick={closeNavBar}>
                                <Link class="nav-link" to="/services">Services</Link>
                            </li>
                            <li class="nav-item" onClick={closeNavBar}>
                                <Link to='/contact-us' class="nav-link">Contact</Link>
                            </li>
                            <li class="nav-item" onClick={closeNavBar}>
                                <Link to='/pricing' class="nav-link">Pricing</Link>
                            </li>
                            <li class="nav-item" onClick={closeNavBar}>
                                <Link to='/about-us' class="nav-link">About</Link>
                            </li>
                            <li class="nav-item" onClick={closeNavBar}>
                                <Link to="/chat" class="nav-link">Chat</Link>
                            </li>
                        </ul>

                        {(context.token != "" && context.token !== null) ?
                            <button onClick={()=>{closeNavBar();navigate('/account')}} class="btn d-flex align-items-center" type="submit"><img src={userIcon} alt="user" class="me-2" />
                                My Account</button>

                            :
                            <button onClick={() => navigate('/login')} class="btn d-flex align-items-center" type="submit"><img src={userIcon} alt="user" class="me-2" />
                                Sign in</button>
                        }
                        <span class="vr d-md-flex d-none"></span>

                        <button class="btn btn-service" onClick={()=>{closeNavBar();navigate('/services')}}>Book Service</button>


                    </div>
                </div>
            </nav>

            {/* <!--- offcanvs --> */}

            {/* <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">

                <div class="offcanvas-header">
                    <h5 class="offcanvas-title color fw-700" id="offcanvasExampleLabel">Menus</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link" href="#">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Services</a>
                        </li>
                        <li class="nav-item">
                            <Link to='/contact-us' class="nav-link">Contact</Link>
                        </li>
                        <li class="nav-item">
                            <Link to='/about-us' class="nav-link">About</Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link">Chat</a>
                        </li>

                        <li class="nav-item">
                            <Link to='/' class="nav-link">
                                <button class="btn d-md-flex align-items-md-center p-0" type="button"><img src={userIcon} alt="user" class="me-2" />
                                    Sign in</button>
                            </Link>
                        </li>
                    </ul>

                    <button class="btn btn-service ">Book Service</button>

                </div>
            </div> */}
        </>
    );
}

export default Header;