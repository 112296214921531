import image1 from 'assests/images/image-1.png'
import image2 from 'assests/images/image-2.png'
import icon1 from 'assests/images/icon-1.svg'
import icon2 from 'assests/images/icon-2.svg'
import icon3 from 'assests/images/icon-3.svg'
import icon4 from 'assests/images/icon-4.svg'
import cheksvg from 'assests/images/check.svg'
import { useContext, useEffect, useState } from 'react'
import { getCities, getLanguages, getServices, getStates } from 'services/services'
import { toast } from 'react-toastify'
import AppContext from 'context/appContext'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const Home = () => {

    const navigate = useNavigate()
    const context = useContext(AppContext)
    const [services,setServices]=useState([])
    console.log('context--home->',context)
    const handleBookings = (type,service)=>{
        if(type=="immediate"){
            const date=moment().add(1,'hour').toISOString()
            service.selectedDate=date
            service.jobType = "immediate"
            navigate('/service-form',{state:service})
        }
        if(type=="schedule"){
            service.jobType = "schedule"
            navigate(`/service/${service.service_name}`, { state: service })
        }
}
    useEffect(()=>{

            const GetStatesList = () => {
                getStates().then(response => {
                    if (response.status == 200) {
                        context.setStates(response.data.list)
                        localStorage.setItem('states',JSON.stringify(response.data.list))
                    }
                }).catch(err => {
                    toast.error(err.response.data.message)
                })
            }
            const GetCitiesList = ()=>{
                getCities().then(res=>{
                    if(res.status==200){
                        context.setCities(res.data.data)
                        localStorage.setItem('cities',JSON.stringify(res.data.data))
                    }
                })
            }
            const GetLanguages = ()=>{
                getLanguages().then(res=>{
                    if(res.status==200){
                        context.setLanguages(res.data.list)
                        localStorage.setItem('languages',JSON.stringify(res.data.list))
                    }
                })
            }
        
        const GetServices = ()=>{
            getServices().then(response=>{
                if(response.status==200){
                    setServices(response.data.list)
                }
            }).catch(err=>{
                toast.error(err.response.data.message)
            })
        }
        GetServices()
        GetStatesList()
        GetCitiesList()
        GetLanguages()

    },[])
    return (
        <>
            <div class="container mt-5 text-black">
                <div class="row">
                    <div class="col-md-6">
                        <div class="section-1">
                            <h1 class="fw-700">Mobile Notarization to Your Location!</h1>
                            <p class="py-lg-4 f-size w-75">Welcome to Mobile Notary X, where convenience meets professionalism in mobile notary services. We understand that your time is valuable, which is why we've redefined the notary experience by bringing our services directly to you.</p>
                            <button class="btn btn-service" onClick={()=>navigate('/services')}>Book Service</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src={image1} alt="image" class="img-fluid m-img"/>
                    </div>
                </div>
            </div>

            {/* <!-- Services --> */}

            <div class="bg-color text-black mt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-8 mx-auto">
                            <div class="text-center ">
                                <h6 class="text-uppercase fw-900">Ready to Assist</h6>
                                <h1 class="fw-900"> <span class="color">Our seamless mobile notary services</span></h1>
                                <p>At Mobile Notary X, we offer a variety of notarization services.</p>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="row">
                                {services.length!==0 && services.map((service)=>
                                <div class="col-lg-6">
                                    <div class="card mb-4 border-0">
                                        <div class="card-body p-4">
                                            <div class="row g-0">
                                                <div class="col-md-2">
                                                    <img src={context.img_uri+service.img} class="img-fluid rounded-start" alt="icons"/>
                                                </div>
                                                <div class="col-md-10">
                                                    <h5 class="card-title fw-900 m-img">{service.service_name}</h5>
                                                    <p class="card-text">{service.service_description}</p>
                                                    {/* <p style={{fontWeight:"bolder"}} class="card-text">$ {service.price}</p> */}
                                                    {/* <button class="btn btn-book" onClick={()=>navigate(`/service/${service.service_name}`,{state:service})}>Book Now</button> */}
                                                    <button class="btn btn-book dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Book Now</button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" onClick={(e)=>handleBookings('immediate',service)} >Immediate</a>
                                                    <a class="dropdown-item" onClick={(e)=>handleBookings('schedule',service)}>Schedule</a>
                                                    
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Where Section --> */}

            <div class="container mt-5 text-black">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <img src={image2} alt="image" class="img-fluid" />
                    </div>
                    <div class="col-md-6">
                        <p class="mb-0 m-img"><small class="fw-bolder">Mobile Notary X</small></p>
                        <h1 class="fw-bolder mb-3 f-size">Our Commitment to Convenience and Efficiency</h1>
                        <p class="f-size pb-3">Experience the ease and peace of mind with our customer-centric approach. At Mobile Notary X, we ensure a hassle-free, efficient, and accurate notarization process through our mobile service, allowing you to focus on your priorities. With our reliable, punctual, and dedicated approach, rest assured that we'll be there when you need us, committed to getting it right the first time. Schedule an appointment today and let us bring professional notary services directly to you.</p>
                        <div class="mb-3">
                            <img src={cheksvg} alt="check" class="me-lg-2 me-md-1 img-check"/>
                                Detail to every document we handle
                        </div>
                        <div class="mb-3">
                            <img src={cheksvg} alt="check" class="me-lg-2 me-md-1 img-check"/>
                                Notarization with personalized attention
                        </div>
                        <div class="mb-3">
                            <img src={cheksvg} alt="check" class="me-lg-2 me-md-1 img-check"/>
                                Ensure sensitive information is private
                        </div>
                        {/* <div>
                            <img src={cheksvg} alt="check" class="me-lg-2 me-md-1 img-check"/>
                                Guiding through the intricacy
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;