import axios from "axios";
import AppContext from "context/appContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getFaqsList } from "services/services";

const Faq = () => {
    const [faqs, setFaqs] = useState([])
    const context = useContext(AppContext)
    useEffect(() => {
        const Faqs = () => {
            getFaqsList().then(response => {
                if (response.status == 200) {
                    setFaqs(response.data.list)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
      
            Faqs()
        
    }, [])
    return (
        <>
            <div class="bg-faq">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">FAQ's</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#" class="text-decoration-none text-white fw-700">Home</a></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">Faq's</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container mt-5 text-black">
                <div class="row">
                    <div class="col-md-12">

                        <div class="accordion" id="accordionExample">
                            {faqs?.length !== 0 && faqs.map((faq) =>
                                // <div class="accordion-item">
                                //     <h2 class="accordion-header" id={faq.question}>
                                //         <button class="accordion-button bg-none fw-900 shadow-none text-black" type="button" data-bs-toggle="collapse" data-bs-target={`#${faq._id}`} aria-expanded="true" aria-controls={faq._id}>
                                //             {faq.question}
                                //         </button>
                                //     </h2>
                                //     <div id={faq._id} class="accordion-collapse collapse show" aria-labelledby={faq.question} data-bs-parent="#accordionExample">
                                //         <div class="accordion-body">
                                //             <p class="text-muted fw-600">{faq.answer}</p>
                                //         </div>
                                //     </div>
                                // </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id={faq._id}>
                                        <button
                                            class="accordion-button bg-none fw-900 shadow-none text-black collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#acc${faq._id.toString()}`}
                                            aria-expanded="true"
                                            aria-controls={faq._id}
                                        >
                                            {faq.question}
                                        </button>
                                    </h2>
                                    <div
                                        id={"acc"+faq._id}
                                        class="accordion-collapse collapse"
                                        aria-labelledby={faq._id}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div class="accordion-body">
                                            <p class="text-muted fw-600">{faq.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}

export default Faq;