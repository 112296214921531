
import React,{useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiSolidHide,BiShow } from "react-icons/bi";
import { FormValidation, FormValidationOnChange } from "./ResetPassValidation";
import { toast } from "react-toastify";
import { userResetPassword } from 'services/services';


import axios from 'axios';
const ResetPassword = () => {
    const [inputField, setInputField] = useState({
        password: "",
        confirmPassword: ''
    });
    const [errField, setErrField] = useState({
        passwordErr: "",
        confirmPasswordErr: ''
    });
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const createNewPasswordHadler = (e) => {
        e.preventDefault();

        if (FormValidation({ inputField, setErrField })) {
            if (inputField?.password.trim() !== inputField.confirmPassword.trim()) {
                return toast.error("Password and confirmed password do not match")
            }
            let user = localStorage.getItem('emailForgetPass')
                const inputData = {
                    email:user,
                    password:inputField?.password
                }
                userResetPassword(inputData)
                .then((res) => { 
                
                    if (res?.status==200) {
                        toast.success(res?.data?.message);
                       navigate("/login");
                    }
                })
                .catch((err) => {
                    if (err.response?.status == 400) {
                        toast.error(err.response.data.message)
                    }
                   
                  });
            

        }
        //navigate('/login')
    }

    const inputChangeHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
         let inputName = e.target.name
        let inputValue = e?.target?.value
         FormValidationOnChange({ inputValue, setErrField, inputName })
    }



    return (
        <>
           

            <div class="container mt-5 text-black">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-8">
                        <h2 class="text-center fw-900">Reset your Password</h2>
                        {/* <p class="text-center fw-600">Your new password must be different from previously used passwords.</p> */}
                        <form action="" class="contact-form mt-5">
                        <div class="mb-4 position-relative">
                                <label for="password" class="form-label">Password</label>
                                <input type={showPassword ? "text" : "password"} class="form-control" 
                                name="password"
                                value={inputField?.password}
                                onChange={inputChangeHandler}
                                 />
                                <div className='password-hide-show'  onClick={togglePasswordVisibility}>{showPassword ? <BiShow /> : <BiSolidHide />} </div>
                                
                            </div>
                            <div class="mb-4 position-relative">
                                <label for="password" class="form-label">Confirm Password</label>
                                <input type={showConfirmPassword ? "text" : "password"} class="form-control"
                                name="confirmPassword"
                                value={inputField?.confirmPassword}
                                onChange={inputChangeHandler}
                                 />
                                <div className='password-hide-show' onClick={toggleConfirmPasswordVisibility}>{showConfirmPassword ? <BiShow /> : <BiSolidHide />} </div>                           
                            </div>
                            <div class="mx-xl-5 px-md-5 my-5">
                                <button class="btn btn-book w-100 py-3" onClick={createNewPasswordHadler}>Create New Password</button>
                            </div>                       
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}



export default ResetPassword