import aboutimg from 'assests/images/smiling-business-aboutus.jpg'

import docSvg from 'assests/images/doc.svg'
import doc2svg from 'assests/images/doc-2.svg'
import locksvg from 'assests/images/lock.svg'
import { useNavigate } from 'react-router-dom'
import { getServices } from 'services/services'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import AppContext from 'context/appContext'


const AboutUs = () => {
    const navigate = useNavigate()
    const context = useContext(AppContext)
    const [services,setServices]=useState([])
    useEffect(()=>{
        
        const GetServices = ()=>{
            getServices().then(response=>{
                if(response.status==200){
                    setServices(response.data.list)
                }
            }).catch(err=>{
                toast.error(err.response.data.message)
            })
        }
        GetServices()
    },[])

    return (
        <>
            <div class="bg-about">
                <div class="container">
                    <div class="col-md-12">
                        <h1 class="text text-white fw-700">About</h1>
                        <nav aria-label="breadcrumb" >
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#" class="text-decoration-none text-white fw-700">Home</a></li>
                                <li class="breadcrumb-item active text-white fw-700" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container mt-5 text-black">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <img src={aboutimg} alt="image" class="img-fluid" />
                    </div>
                    <div class="col-md-6">
                        <p class="mb-0 m-img"><small class="fw-bolder">About Us</small></p>
                        <h1 class="fw-900">Mobile Notary X</h1>
                        <p class="f-size f-600">Welcome to Mobile Notary X, your trusted partner for efficient and convenient mobile notary services. We pride ourselves on offering a unique and customer-centric approach to notarizing your important documents. Gone are the days of navigating traffic or rearranging your schedule to visit a notary's office. At Mobile Notary X, we prioritize your convenience. Our team of dedicated notaries travels to your preferred location – whether it's your home, office, or any other place that suits you best.
                        </p>

                        <button onClick={() => navigate('/contact-us')} nav class="btn btn-service ">Contact Us</button>
                    </div>

                    <div class="col-md-12 mt-4">
                        <h3 class="fw-900">Our Mission</h3>
                        <p class="f-size f-600">
                            At Mobile Notary X, our mission is to redefine the notary experience by prioritizing your convenience without compromising on professionalism, efficiency or quality. We aim to make the notarization process seamless, reliable, and easily accessible.

                            Experience the ease and convenience of professional mobile notary services with Mobile Notary X. Schedule an appointment today, and let us take the stress out of notarizing your documents by bringing our services directly to your doorstep.</p>
                    </div>

                    <div class="bg-color text-black mt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-8 mx-auto">
                            <div class="text-center ">
                                <h6 class="text-uppercase fw-900">Ready to Assist</h6>
                                <h1 class="fw-900"> <span class="color">Our seamless mobile notary services</span></h1>
                                <p>At Mobile Notary X, we offer a variety of notarization services.</p>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="row">
                                {services.length!==0 && services.map((service)=>
                                <div class="col-lg-6">
                                    <div class="card mb-4 border-0">
                                        <div class="card-body p-4">
                                            <div class="row g-0">
                                                <div class="col-md-2">
                                                    <img src={context.img_uri+service.img} class="img-fluid rounded-start" alt="icons"/>
                                                </div>
                                                <div class="col-md-10">
                                                    <h5 class="card-title fw-900 m-img">{service.service_name}</h5>
                                                    <p class="card-text">{service.service_description}</p>
                                                    {/* <p style={{fontWeight:"bolder"}} class="card-text">$ {service.price}</p> */}
                                                    <button class="btn btn-book" onClick={()=>navigate('/services')}>Book Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    <div class="container mt-5 text-black">
                        <div class="row">

                            <div class="col-lg-6 col-md-10 mx-auto">
                                <div class="text-center ">
                                    <h6 class="text-uppercase fw-900">why choose us</h6>
                                    <h1 class="fw-900"> <span class="color">Many reasons</span> to choose Mobile Notary X</h1>
                                </div>
                            </div>
                        </div>


                        <div class="row choose mt-5 justify-content-center">

                            <div class="col-lg-4 col-md-6">

                                <div class="card text-center">
                                    <div class="card-body">
                                        <img src={docSvg} class="mx-auto" alt="image" width="60px" height="60px"/>

                                            <h3 class="fw-700 mt-4 ">Convenience</h3>
                                            <p class="card-text">We focus on convenience. We know your time is precious, so we bring our skilled notaries to you through our mobile service.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-6 m-img">

                                <div class="card text-center">
                                    <div class="card-body">
                                        <img src={doc2svg} class="mx-auto" alt="image" width="60px" height="60px"/>

                                            <h3 class="fw-700 mt-4 ">Commitment</h3>
                                            <p class="card-text">We're committed to being reliable, punctual, and accurate. At Mobile Notary X, your documents are handled with professionalism and precision, ensuring they're done right the first time.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-6 m-img md">

                                <div class="card text-center">
                                    <div class="card-body">
                                        <img src={locksvg} class="mx-auto" alt="image" width="60px" height="60px"/>

                                            <h3 class="fw-700 mt-4 ">Customer Satisfaction</h3>
                                            <p class="card-text">We value great customer service. Our helpful notaries are committed to giving you personalized guidance through the notarization process, addressing any questions or concerns you might have. Your satisfaction matters most to us.</p>
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>

                </div>
            </div>


            {/* <!-- Services --> */}

            {/* <div class="bg-color text-black mt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-8 mx-auto">
                            <div class="text-center ">
                                <h6 class="text-uppercase fw-900">Ready to Assist</h6>
                                <h1 class="fw-900"> <span class="color">Our Services</span> help to
                                    resolve your situation </h1>
                                <p>Consectetur adipiscing elit, sed do eiusm onsectetur
                                    adipiscing elit, sed do eiusm od tempor.</p>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card mb-4 border-0">
                                        <div class="card-body p-4">
                                            <div class="row g-0">
                                                <div class="col-md-2">
                                                    <img src={icon1} class="img-fluid rounded-start" alt="icons" />
                                                </div>
                                                <div class="col-md-10">
                                                    <h5 class="card-title fw-900 m-img">Standard Notarization</h5>
                                                    <p class="card-text">Consectetur adipiscing elit, sed do eiusm onsectetur
                                                        adipiscing elit, sed do eiusm od tempor.</p>
                                                    <button class="btn   btn-book">Book Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="card mb-4 border-0">
                                        <div class="card-body p-4">
                                            <div class="row g-0">
                                                <div class="col-md-2">
                                                    <img src={icon2} class="img-fluid rounded-start" alt="icons" />
                                                </div>
                                                <div class="col-md-10">
                                                    <h5 class="card-title fw-900 m-img">Real Estate Transaction</h5>
                                                    <p class="card-text">Consectetur adipiscing elit, sed do eiusm onsectetur
                                                        adipiscing elit, sed do eiusm od tempor.</p>
                                                    <button class="btn btn-book">Book Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="card mb-4 border-0">
                                        <div class="card-body p-4">
                                            <div class="row g-0">
                                                <div class="col-md-2">
                                                    <img src={icon3} class="img-fluid rounded-start" alt="icons" />
                                                </div>
                                                <div class="col-md-10">
                                                    <h5 class="card-title fw-900 m-img">I-9 Verification</h5>
                                                    <p class="card-text">Consectetur adipiscing elit, sed do eiusm onsectetur
                                                        adipiscing elit, sed do eiusm od tempor.</p>
                                                    <button class="btn btn-book ">Book Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="card mb-4 border-0">
                                        <div class="card-body p-4">
                                            <div class="row g-0">
                                                <div class="col-md-2">
                                                    <img src={icon4} class="img-fluid rounded-start" alt="icons" />
                                                </div>
                                                <div class="col-md-10">
                                                    <h5 class="card-title fw-900 m-img">Oath Administration/ Sworn Statement</h5>
                                                    <p class="card-text">Consectetur adipiscing elit, sed do eiusm onsectetur
                                                        adipiscing elit, sed do eiusm od tempor.</p>
                                                    <button class="btn btn-book ">Book Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <!-- Choose Section --> */}

            {/* <div class="container mt-5 text-black">
                <div class="row">

                    <div class="col-lg-6 col-md-10 mx-auto">
                        <div class="text-center ">
                            <h6 class="text-uppercase fw-900">why choose us</h6>
                            <h1 class="fw-900"> <span class="color">Many reason</span> why you should use
                                our service provided </h1>
                        </div>
                    </div>
                </div>


                <div class="row choose mt-5 justify-content-center">

                    <div class="col-lg-4 col-md-6">

                        <div class="card text-center">
                            <div class="card-body">
                                <img src={docSvg} class="mx-auto" alt="image" width="60px" height="60px" />

                                <h3 class="fw-700 mt-4 ">Lorem Ipsum Dummy</h3>
                                <p class="card-text">Lorem Ipsum is simply dummy text
                                    printing and typesetting industry.
                                    Lorem Ipsumhas been theindustry's
                                    standard dummy text ever since
                                    the 1500s,when an unknown
                                    printer took a galley of type and
                                    scrambled it to make.</p>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 m-img">

                        <div class="card text-center">
                            <div class="card-body">
                                <img src={doc2svg} class="mx-auto" alt="image" width="60px" height="60px" />

                                <h3 class="fw-700 mt-4 ">Lorem Ipsum Dummy</h3>
                                <p class="card-text">Lorem Ipsum is simply dummy text
                                    printing and typesetting industry.
                                    Lorem Ipsumhas been theindustry's
                                    standard dummy text ever since
                                    the 1500s,when an unknown
                                    printer took a galley of type and
                                    scrambled it to make.</p>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 m-img md">

                        <div class="card text-center">
                            <div class="card-body">
                                <img src={locksvg} class="mx-auto" alt="image" width="60px" height="60px" />

                                <h3 class="fw-700 mt-4 ">Lorem Ipsum Dummy</h3>
                                <p class="card-text">Lorem Ipsum is simply dummy text
                                    printing and typesetting industry.
                                    Lorem Ipsumhas been theindustry's
                                    standard dummy text ever since
                                    the 1500s,when an unknown
                                    printer took a galley of type and
                                    scrambled it to make.</p>
                            </div>
                        </div>
                    </div>

                </div>



            </div> */}
        </>
    );
}

export default AboutUs;